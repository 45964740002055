/**
 * Remove any whitespace from `value` and return a new string wrapped in brackets, e.g. "1; 2 " => "[1;2]"
 * @param value A string to be wrapped
 * @returns A new "array string" wrapped in brackets
 */
export const wrapWithBrackets = (value: string) => {
	return '[' + value.trim().replace(/\s/g, '') + ']'
}

/**
 * Remove the brackets from `value`, e.g. "[1;2]" => "1;2"
 * @param value A string with brackets
 * @returns The `value` without brackets
 */
export const unwrapBrackets = (value: string | null | undefined) => {
	return value ? value.replace(/[[\]]/g, '') : ''
}

/**
 * Remove any trailing separators from `value`, if any. e.g. "1;2;" => "1;2"
 * @param value A comma or semicolon delimited string
 * @returns The string without the trailing separators
 */
export const removeTrailingSeparators = (value: string) => {
	return value.replace(/[;,]+\s*$/, '')
}

/**
 * Remove any trailing separators from `arrayString`, if any. e.g. "[1;2;]" => "[1;2]"
 * @param arrayString A comma or semicolon delimited array string
 * @returns The array string without the trailing separators inside the brackets
 */
export const removeTrailingSeparatorsFromArrayString = (arrayString?: string | null) => {
	return arrayString ? arrayString.replace(/[;,]+]\s*$/, ']') : arrayString
}

/**
 * If `value` is a semicolon delimited array string, return an array of its individual string values.
 * @param semicolonArrayString A semicolon delimited array string, e.g. "[]" or "[1;2]"
 * @returns An array of string values
 */
export const parseSemicolonArrayString = (semicolonArrayString: string | null | undefined) => {
	return semicolonArrayString && semicolonArrayString !== '[]' ? unwrapBrackets(semicolonArrayString).split(';') : []
}

/**
 * Given an array of strings, join them with a semicolon, wrap in brackets, and remove any trailing separators.
 * @param array An array of strings
 * @returns A new semicolon delimited array string
 */
export const serializeToSemicolonArrayString = (array?: string[]) =>
	removeTrailingSeparatorsFromArrayString(wrapWithBrackets(array ? array.join(';') : '')) as string

/**
 * Mostly used to generate an id/href anchor, this method will convert to lower case, and replace spaces and forward slashes with dashes
 * @param title The string to convert, usually a title that needs to have an anchor for linking
 * @returns string in lower case without forward slashes and with dashes instead of spaces
 */
export const toLowerCaseDashedString = (title: string) => title.toLocaleLowerCase().replace(/(\s\/\s)|\/|\s/g, '-')

export const ordinalToAlphaLabel = (ordinal: number): string => {
	const alphabetLength = 26
	const lowercaseANumber = 97

	let label = ''
	let currentOrdinal = ordinal
	while (currentOrdinal > 0) {
		const index = currentOrdinal - 1
		const letterNumber = index % alphabetLength
		const letterChar = String.fromCharCode(letterNumber + lowercaseANumber)
		label = letterChar + label
		currentOrdinal = (index - letterNumber) / alphabetLength
	}
	return label
}

export const booleanToStringCamelCase = (value: boolean) => {
	const stringValue = value.toString()
	return stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
}
