import { Dictionary } from 'lodash'
import { Model } from 'studiokit-scaffolding-js'
import { DiffObject } from './DiffObject'
import { NUMBER_FORMAT_TYPE } from './NumberFormatType'
import {
	VariableArtifact,
	VariableArtifactBaseModel,
	VariableArtifactRequestModel,
	VariableArtifactState,
	VariableArtifactStateDiff
} from './VariableArtifact'

export enum VARIABLE_TYPE {
	NUMBER_SET = 'NumberSet',
	RANDOM_NUMBER = 'RandomNumber',
	CALCULATED_VALUE = 'CalculatedValue',
	RICH_TEXT = 'RichText'
}

/**
 * The common `Variable` properties from both the API and used in CreateOrEdit.
 */
export interface VariableBaseModel {
	id?: number
	guid?: string
	name: string
	variableType: VARIABLE_TYPE
	variableArtifacts: VariableArtifactBaseModel[]
}

/**
 * A `Variable` returned from the API
 */
export interface Variable extends Model, VariableBaseModel {
	id: number
	problemId: number
	parameters: string | null
	variableArtifacts: VariableArtifact[]
}

/**
 * Used for CreateOrEdit state
 */
export interface VariableState extends VariableBaseModel {
	parameters?: string[] | Partial<RandomNumberVariableParameters> | CalculatedValueVariableParameters
	variableArtifacts: VariableArtifactState[]
}

export interface RandomNumberVariableParameters {
	min: string
	max: string
	step: string
	blacklist: string | null
	numberFormatType: NUMBER_FORMAT_TYPE
}

export interface CalculatedValueVariableParameters {
	formula: string
	numberFormatType: NUMBER_FORMAT_TYPE
	rounding: number
}

export interface NumberSetVariableState extends Omit<VariableState, 'parameters'> {
	parameters?: string[]
	variableType: VARIABLE_TYPE.NUMBER_SET
}

export interface RandomNumberVariableState extends Omit<VariableState, 'parameters'> {
	parameters: Partial<RandomNumberVariableParameters>
	variableType: VARIABLE_TYPE.RANDOM_NUMBER
}

export interface CalculatedValueVariableState extends Omit<VariableState, 'parameters'> {
	parameters: CalculatedValueVariableParameters
	variableType: VARIABLE_TYPE.CALCULATED_VALUE
}

export interface RichTextVariableState extends Omit<VariableState, 'parameters'> {
	variableType: VARIABLE_TYPE.RICH_TEXT
}

/**
 * Used for ChangeSummaryModal diff
 */
export interface VariableStateDiff {
	id?: DiffObject<number | undefined>
	guid?: DiffObject<string | undefined>
	name?: DiffObject<string>
	variableArtifacts?: Dictionary<VariableArtifactStateDiff | DiffObject<VariableArtifactState | undefined>>

	// NOTE: since other properties cannot be changed RE: ChangeSummaryModal, they have not been added here
}

/**
 * Used for API POST/PUT
 */
export interface VariableRequestModel extends Omit<VariableBaseModel, 'guid' | 'variableArtifacts'> {
	parameters: string | null
	variableArtifacts: VariableArtifactRequestModel[]
}
