import update from 'immutability-helper'
import { cloneDeep, debounce, get, isUndefined } from 'lodash'
import { ComponentType, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	dispatchAction,
	MANAGE_TABLE_TAB,
	MODEL_FETCH_RESULT_ACTION_TYPE,
	MODEL_STATUS,
	ModelCollection,
	ModelFetchResultAction
} from 'studiokit-scaffolding-js'
import { useCollection } from 'studiokit-scaffolding-js/lib/hooks/useCollection'
import { usePrevious } from 'studiokit-scaffolding-js/lib/hooks/usePrevious'
import { getModelFetchResult } from 'studiokit-scaffolding-js/lib/utils/model'
import { SparseProblem } from '../../../types/Problem'
import { ReduxState } from '../../../types/ReduxState'
import { SharedLibrary } from '../../../types/SharedLibrary'
import { searchProblems } from '../../../utils/search'
import { sortByShareable } from '../../../utils/searchResults'
import { SearchResultsContext, SearchResultsContextType } from './SearchResultsContext'
import { SearchResult } from './index'

import './SearchResults.css'

const searchProblemsAndSetResults = (
	problemsArray: SparseProblem[],
	problemIdsNotShareable: Set<number>,
	problemIdsAlreadyShared: Set<number>,
	setSearchResults: (searchResults: SearchResult[]) => void,
	filteredSharedLibraryIdsSet?: Set<number>,
	currentUserId?: string,
	keywords?: string
) => {
	// get available problems (re-use manage table search), optionally filtered by keywords and/or shared libraries
	const dataByTab = searchProblems({
		modelArray: problemsArray,
		keywords,
		filteredSharedLibraryIdsSet,
		isFilteringOwnProblems: true,
		currentUserId
	})
	const availableArray = dataByTab[MANAGE_TABLE_TAB.AVAILABLE]
	const searchResults = availableArray.map(
		p =>
			({
				problem: p,
				isSelected: false,
				canBeShared: !problemIdsNotShareable.has(p.id),
				isAlreadyShared: problemIdsAlreadyShared.has(p.id)
			} as SearchResult)
	)
	setSearchResults(sortByShareable(searchResults))
}

export function SearchResultsContextManager<TOwnProps extends {}>(
	WrappedComponent: ComponentType<TOwnProps>,
	hidePartialCreditIndicators?: boolean,
	/** Set to `true` when using `problemIdsAlreadyShared` and `problemIdsNotShareable`, e.g. in `SharedLibrary/EditProblems`,
	 * to wait to render search results until the problem Id sets have been initialized by the wrapped component. */
	delayInitForProblemSharing?: boolean
): FunctionComponent<TOwnProps> {
	function SearchResultsContextManagerComponent(props: TOwnProps) {
		const [keywords, setKeywords] = useState('')
		const [searchResults, setSearchResults] = useState<Array<SearchResult>>()
		const [isDrawerOpen, setIsDrawerOpen] = useState(true)
		const [hasFetchedProblems, setHasFetchedProblems] = useState(false)
		const [filteredSharedLibraryIds, setFilteredSharedLibraryIds] = useState<Set<number>>(new Set<number>())
		const [isFilteringOwnProblems, setIsFilteringOwnProblems] = useState(false)
		const currentUserId = useSelector((state: ReduxState) => state.models.user?.userInfo?.id)
		// if init is delayed, we wait for the wrapped component to initialize these sets
		// otherwise, if disabled, initialize them immediately
		const [problemIdsAlreadyShared, setProblemIdsAlreadyShared] = useState<Set<number> | undefined>(
			!delayInitForProblemSharing ? new Set() : undefined
		)
		const [problemIdsNotShareable, setProblemIdsNotShareable] = useState<Set<number> | undefined>(
			!delayInitForProblemSharing ? new Set() : undefined
		)

		const { model: problems, modelArray: problemsArray, modelStatus, load } = useCollection<SparseProblem>({
			modelName: 'problems',
			pathParams: [],
			disableAutoLoad: true
		})
		const prevProblems = usePrevious(problems)
		const fetchResult = getModelFetchResult(prevProblems, problems)

		const sharedLibraries: ModelCollection<SharedLibrary> = useSelector((state: ReduxState) => {
			const sl = get(state.models, `sharedLibraries`)
			return sl ? sl : {}
		})

		// Search function is debounced to prevent it being rapidly called. UseMemo with no dependencies ensures we keep
		// the same debounced function between renders.
		const debouncedSearch = useMemo(() => debounce(searchProblemsAndSetResults), [])

		// load problems with sparse relations
		const loadProblems = useCallback(
			() =>
				load({
					queryParams: {
						withRelations: true
					},
					replaceValue: true
				}),
			[load]
		)

		// Convenience method to not need to pass parameters to search function when used outside of hooks
		const search = useCallback(
			() =>
				debouncedSearch(
					problemsArray,
					problemIdsNotShareable ?? new Set(),
					problemIdsAlreadyShared ?? new Set(),
					setSearchResults,
					filteredSharedLibraryIds,
					isFilteringOwnProblems ? currentUserId : undefined,
					keywords
				),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[
				debouncedSearch,
				keywords,
				problemIdsAlreadyShared,
				problemIdsNotShareable,
				filteredSharedLibraryIds,
				isFilteringOwnProblems,
				currentUserId,
				problems._metadata?.fetchedAt
			]
		)

		// manually update `fetchedAt` metadata on all problems to force other useEffects to trigger
		const triggerRerender = useCallback(() => {
			const updatedProblems = cloneDeep(problems)
			if (updatedProblems._metadata) {
				updatedProblems._metadata.fetchedAt = new Date()
			}
			dispatchAction<ModelFetchResultAction>({
				type: MODEL_FETCH_RESULT_ACTION_TYPE.FETCH_RESULT_RECEIVED,
				modelPath: 'problems',
				data: updatedProblems,
				replaceValue: true
			})
		}, [problems])

		// watch for problems to be loaded
		useEffect(() => {
			if (fetchResult.isFinished && fetchResult.isSuccess && !hasFetchedProblems) {
				setHasFetchedProblems(true)
			}
		}, [
			fetchResult.isFinished,
			fetchResult.isSuccess,
			hasFetchedProblems,
			problems._metadata?.fetchedAt // custom
		])

		// initialize default search results after problems are loaded and problemId sets are initialized
		useEffect(() => {
			if (
				hasFetchedProblems &&
				problemIdsNotShareable !== undefined &&
				problemIdsAlreadyShared !== undefined &&
				searchResults === undefined
			) {
				searchProblemsAndSetResults(
					problemsArray,
					problemIdsNotShareable,
					problemIdsAlreadyShared,
					setSearchResults
				)
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			hasFetchedProblems,
			problemIdsAlreadyShared,
			problemIdsNotShareable,
			searchResults,
			problems._metadata?.fetchedAt // custom
		])

		// trigger search, but only after default search results have been initialized
		const areSearchResultsInitialized = searchResults !== undefined
		useEffect(() => {
			if (areSearchResultsInitialized)
				debouncedSearch(
					problemsArray,
					problemIdsNotShareable ?? new Set(),
					problemIdsAlreadyShared ?? new Set(),
					setSearchResults,
					filteredSharedLibraryIds,
					isFilteringOwnProblems ? currentUserId : undefined,
					keywords
				)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [
			keywords,
			problemIdsAlreadyShared,
			problemIdsNotShareable,
			filteredSharedLibraryIds,
			debouncedSearch,
			areSearchResultsInitialized,
			isFilteringOwnProblems,
			currentUserId,
			problems._metadata?.fetchedAt // custom
		])

		const toggleSearchResultSelected = useCallback(
			(event: any) => {
				const isSelected: boolean = event.target.checked
				const name: string = event.target.name
				const index = parseInt(name.substring(name.indexOf('-') + 1), 10)
				setSearchResults(
					update(searchResults, {
						[index]: {
							$merge: { isSelected }
						}
					})
				)
			},
			[searchResults]
		)

		const setSearchResultsSelected = useCallback(
			(isSelected: boolean) => {
				if (isUndefined(searchResults)) {
					return
				}
				const newSearchResults = cloneDeep(searchResults)
				newSearchResults.forEach(sr => {
					if (sr.canBeShared && !sr.isAlreadyShared) sr.isSelected = isSelected
				})
				setSearchResults(newSearchResults)
			},
			[searchResults]
		)

		const selectAllSearchResults = useCallback(() => {
			setSearchResultsSelected(true)
		}, [setSearchResultsSelected])

		const unselectSearchResults = useCallback(() => {
			setSearchResultsSelected(false)
		}, [setSearchResultsSelected])

		const handleKeywordsChange = useCallback((event: any) => {
			setKeywords(event.target.value)
		}, [])

		const handleKeywordsKeyDown = useCallback(
			(event: any) => {
				if (event.key === 'Enter') {
					search()
				}
			},
			[search]
		)

		const toggleIsDrawerOpen = useCallback(() => {
			setIsDrawerOpen(!isDrawerOpen)
		}, [isDrawerOpen])

		const resetSearch = useCallback(() => {
			setKeywords('')
			setFilteredSharedLibraryIds(new Set<number>())
			setIsFilteringOwnProblems(false)
		}, [])

		const doTagSearch = useCallback((tag: string) => {
			setKeywords(tag)
		}, [])

		const setUnshareableProblemIds = (unshareableProblemIds: number[]) => {
			setProblemIdsNotShareable(new Set(unshareableProblemIds))
		}

		const addAlreadySharedProblemIds = useCallback(
			(sharedProblemIds: number[]) => {
				setProblemIdsAlreadyShared(
					problemIdsAlreadyShared === undefined
						? new Set(sharedProblemIds)
						: update(problemIdsAlreadyShared, {
								$add: sharedProblemIds
						  })
				)
			},
			[problemIdsAlreadyShared]
		)

		const removeAlreadySharedProblemId = useCallback(
			(unsharedProblemId: number) => {
				if (!problemIdsAlreadyShared?.has(unsharedProblemId)) return
				setProblemIdsAlreadyShared(
					update(problemIdsAlreadyShared, {
						$remove: [unsharedProblemId]
					})
				)
			},
			[problemIdsAlreadyShared]
		)

		const setSharedLibraryFilter = useCallback(
			(sharedLibraryId: number, isSelected: boolean) => {
				let updatedIdFilter: Set<number>
				if (isSelected) {
					updatedIdFilter = update(filteredSharedLibraryIds, { $add: [sharedLibraryId] })
				} else {
					updatedIdFilter = update(filteredSharedLibraryIds, { $remove: [sharedLibraryId] })
				}
				setFilteredSharedLibraryIds(updatedIdFilter)
			},
			[filteredSharedLibraryIds, setFilteredSharedLibraryIds]
		)

		const contextValue: SearchResultsContextType = useMemo(
			() => ({
				keywords,
				handleKeywordsChange,
				handleKeywordsKeyDown,
				resetSearch,
				loadProblems,
				isLoading: modelStatus !== MODEL_STATUS.READY && modelStatus !== MODEL_STATUS.ERROR,
				search,
				triggerRerender,
				searchResults,
				problemIdsAlreadyShared,
				addAlreadySharedProblemIds,
				removeAlreadySharedProblemId,
				problemIdsNotShareable,
				setUnshareableProblemIds,
				isOpen: isDrawerOpen,
				toggleIsOpen: toggleIsDrawerOpen,
				toggleSearchResultSelected,
				selectAllSearchResults,
				unselectSearchResults,
				doTagSearch,
				setSharedLibraryFilter,
				filteredSharedLibraryIds,
				sharedLibraries,
				isFilteringOwnProblems,
				setIsFilteringOwnProblems,
				hidePartialCreditIndicators
			}),
			[
				keywords,
				handleKeywordsChange,
				handleKeywordsKeyDown,
				resetSearch,
				loadProblems,
				modelStatus,
				problemIdsAlreadyShared,
				problemIdsNotShareable,
				removeAlreadySharedProblemId,
				search,
				triggerRerender,
				searchResults,
				addAlreadySharedProblemIds,
				isDrawerOpen,
				toggleIsDrawerOpen,
				toggleSearchResultSelected,
				selectAllSearchResults,
				unselectSearchResults,
				doTagSearch,
				setSharedLibraryFilter,
				filteredSharedLibraryIds,
				sharedLibraries,
				isFilteringOwnProblems
			]
		)

		return (
			<SearchResultsContext.Provider value={contextValue}>
				<WrappedComponent {...props} />
			</SearchResultsContext.Provider>
		)
	}
	return SearchResultsContextManagerComponent
}
