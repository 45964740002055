import { SearchResult } from '../components/Shared/SearchResults'

/**
 * Sort SearchResult array with shareable items first, already shared problems last, then not shareable problems between
 * the two. each section is also sorted by problemId
 */
export const sortByShareable = (searchResults: SearchResult[]) => {
	const sortByProblemIdFunc = (a: SearchResult, b: SearchResult) => a.problem.id - b.problem.id
	const alreadyShared = searchResults.filter(s => s.isAlreadyShared).sort(sortByProblemIdFunc)
	const notShareable = searchResults.filter(s => !s.canBeShared && !s.isAlreadyShared).sort(sortByProblemIdFunc)
	const shareable = searchResults.filter(s => s.canBeShared && !s.isAlreadyShared).sort(sortByProblemIdFunc)
	return [...shareable, ...alreadyShared, ...notShareable]
}

/**
 * If a SearchResult is not shareable and the previous result in the list is shareable,
 * and it isn't already shared, add a "Not Shareable" header before the result.
 * If it is already shared and the previous result is not already shared,
 * add an "Already Shared" header before the result.
 */
export const getHeaderString = (current: SearchResult, prev?: SearchResult) => {
	if (current.isAlreadyShared) {
		if (!prev || !prev.isAlreadyShared) return 'Already Shared'
	} else if (!current.canBeShared && (!prev || prev.canBeShared || prev.isAlreadyShared)) return 'Not Shareable'
}
