export enum SOLUTION_ENGINE_ERROR_TYPE {
	//#region Missing Parameter Errors

	VariableValuesNotProvided = 'VariableValuesNotProvided',
	SolutionInstancesNotProvided = 'SolutionInstancesNotProvided',
	ResponseTypeNotProvided = 'ResponseTypeNotProvided',
	RoundingNotProvided = 'RoundingNotProvided',
	NumberFormatTypeNotProvided = 'NumberFormatTypeNotProvided',
	ResponseInstancesNotProvided = 'ResponseInstancesNotProvided',
	ResponseInstanceGuidNotProvided = 'ResponseInstanceGuidNotProvided',
	ToleranceNotProvided = 'ToleranceNotProvided',
	ToleranceTypeNotProvided = 'ToleranceTypeNotProvided',
	FormulasNotProvided = 'FormulasNotProvided',
	FormulaValueNotProvided = 'FormulaValueNotProvided',
	FormulaOrdinalNotProvided = 'FormulaOrdinalNotProvided',
	FormulaNotProvided = 'FormulaNotProvided',

	ConditionalGuidNotProvided = 'ConditionalGuidNotProvided',
	ConditionalOrdinalNotProvided = 'ConditionalOrdinalNotProvided',
	ConditionalTypeNotProvided = 'ConditionalTypeNotProvided',

	ConditionalClauseOrdinalNotProvided = 'ConditionalClauseOrdinalNotProvided',
	ComparisonTypeNotProvided = 'ComparisonTypeNotProvided',
	LeftHandVariableNameNotProvided = 'LeftHandVariableNameNotProvided',
	RightHandVariableNameOrValueNotProvided = 'RightHandVariableNameOrValueNotProvided',

	AnswerNotProvided = 'AnswerNotProvided',
	PercentCorrectNotProvided = 'PercentCorrectNotProvided',
	ResponseInstanceOrdinalNotProvided = 'ResponseInstanceOrdinalNotProvided',
	CorrectAnswerNotProvided = 'CorrectAnswerNotProvided',
	FormattedVariableValuesNotProvided = 'FormattedVariableValuesNotProvided',

	//#endregion Missing Parameter Errors

	//#region JSON Decode Errors

	JsonDecodeError = 'JsonDecodeError',
	VariableValuesJsonDecodeError = 'VariableValuesJsonDecodeError',
	FormattedVariableValuesJsonDecodeError = 'FormattedVariableValuesJsonDecodeError',
	InvalidJsonResponseError = 'InvalidJsonResponseError',

	//#endregion JSON Decode Errors

	//#region SymPy Parse Errors

	FormulaParseError = 'FormulaParseError',
	VariableValuesParseError = 'VariableValuesParseError',
	ConditionalClauseParseError = 'ConditionalClauseParseError',
	AnswerParseError = 'AnswerParseError',
	UnitParseError = 'UnitParseError',
	AnswerAndUnitParseError = 'AnswerAndUnitParseError',
	CorrectUnitParseError = 'CorrectUnitParseError',

	//#endregion SymPy Parse Errors

	//#region Compute Conditionals

	LeftHandVariableUndefined = 'LeftHandVariableUndefined',
	RightHandVariableUndefined = 'RightHandVariableUndefined',
	UnableToEvaluateConditionals = 'UnableToEvaluateConditionals',

	//#endregion Compute Conditionals

	//#region Get Answers Errors

	DivideByZeroError = 'DivideByZeroError',
	ToleranceRoundingEdgeCaseError = 'ToleranceRoundingEdgeCaseError',
	ComplexNumberFormatAsRealError = 'ComplexNumberFormatAsRealError',
	UnableToSolve = 'UnableToSolve',

	//#endregion Get Answers Errors

	//#region Evaluate Answer Errors

	AnswerFormatError = 'AnswerFormatError',
	UnableToEvaluate = 'UnableToEvaluate',

	//#endregion Evaluate Answer Errors

	GetAnswersRequestTimeout = 'GetAnswersRequestTimeout',
	EvaluateAnswerRequestTimeout = 'EvaluateAnswerRequestTimeout',

	UnknownError = 'UnknownError'
}

export const EVALUATE_ANSWER_REQUEST_TIMEOUT_MESSAGE = 'There was an issue contacting Variate to evaluate your answer.'

export const getErrorTypeLabel = (errorType: SOLUTION_ENGINE_ERROR_TYPE) => {
	switch (errorType) {
		case SOLUTION_ENGINE_ERROR_TYPE.DivideByZeroError:
			return 'Divide by Zero'
		case SOLUTION_ENGINE_ERROR_TYPE.ToleranceRoundingEdgeCaseError:
			return 'Conflicting Precision'
		case SOLUTION_ENGINE_ERROR_TYPE.GetAnswersRequestTimeout:
		case SOLUTION_ENGINE_ERROR_TYPE.EvaluateAnswerRequestTimeout:
			return 'Request Timed Out'
		case SOLUTION_ENGINE_ERROR_TYPE.ComplexNumberFormatAsRealError:
			return 'Complex Number Format'
		case SOLUTION_ENGINE_ERROR_TYPE.UnknownError:
			return 'Unknown'
		case SOLUTION_ENGINE_ERROR_TYPE.UnitParseError:
			return 'The given unit could not be parsed.'
		default:
			return errorType?.replace(/([A-Z])/g, ' $1').trim()
	}
}
