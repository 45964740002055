import {
	isEntityMatchedByKeywords,
	isEntityUserMatchedByKeyword,
	searchEntities,
	SearchModelArrayParams
} from 'studiokit-scaffolding-js/lib/utils/search'
import ACTIVITY from '../constants/activity'
import { Assessment } from '../types/Assessment'
import { BaseProblem } from '../types/Problem'
import { SharedLibrary } from '../types/SharedLibrary'

//#region Assessments

export const searchAssessments = ({
	modelArray: assessmentsArray,
	canReadyAnyGlobally,
	keywords
}: SearchModelArrayParams<Assessment>) => {
	return searchEntities(
		assessmentsArray,
		isEntityMatchedByKeywords,
		ACTIVITY.ASSESSMENT_READ,
		canReadyAnyGlobally,
		keywords
	)
}

//#endregion Assessments

//#region Problems

export const isProblemMatchedByKeyword = (problem: BaseProblem, keyword: string) =>
	problem.id.toString().includes(keyword) ||
	!!problem.name?.toLowerCase().includes(keyword) ||
	problem.shortStatement.toLowerCase().includes(keyword) ||
	!!problem.tags?.toLowerCase().includes(keyword) ||
	problem.owners.some(owner => isEntityUserMatchedByKeyword(owner, keyword))

export const isProblemMatchedByKeywords = (problem: BaseProblem, keywordsList: string[]) =>
	!keywordsList || keywordsList.length === 0 || keywordsList.every(k => isProblemMatchedByKeyword(problem, k))

export const isProblemIncludedInSharedLibraryFilter = (
	problem: BaseProblem,
	filteredSharedLibraryIds: Set<number>,
	ownerId?: string
) => {
	const hasAnyLibrariesSelected = filteredSharedLibraryIds && filteredSharedLibraryIds.size > 0
	const hasOwnProblemFilterSelected = !!ownerId
	if (
		// Must have a filter selected, otherwise no problems are filtered out
		(hasAnyLibrariesSelected || hasOwnProblemFilterSelected) &&
		// At least one shared library is selected and problem is included in one of the selected libraries
		(!hasAnyLibrariesSelected || !problem.sharedLibraries.some(l => filteredSharedLibraryIds?.has(l.id))) &&
		// or problem is owned by the current user if filter own is selected
		(!hasOwnProblemFilterSelected || !problem.owners.some(o => o.id === ownerId))
	)
		return false
	return true
}

export interface SearchProblemsParams extends SearchModelArrayParams<BaseProblem> {
	filteredSharedLibraryIdsSet?: Set<number>
	isFilteringOwnProblems?: boolean
	currentUserId?: string
}

export const searchProblems = ({
	modelArray: problemsArray,
	canReadyAnyGlobally,
	keywords,
	filteredSharedLibraryIdsSet,
	isFilteringOwnProblems,
	currentUserId
}: SearchProblemsParams) => {
	return searchEntities(
		problemsArray,
		(p: BaseProblem, keywordsList: string[]) =>
			isProblemIncludedInSharedLibraryFilter(
				p,
				filteredSharedLibraryIdsSet ?? new Set(),
				isFilteringOwnProblems ? currentUserId : undefined
			) && isProblemMatchedByKeywords(p, keywordsList),
		ACTIVITY.PROBLEM_READ,
		canReadyAnyGlobally,
		keywords
	)
}

//#endregion Problems

//#region Shared Libraries

export const searchSharedLibraries = ({
	modelArray: sharedLibrariesArray,
	canReadyAnyGlobally,
	keywords
}: SearchModelArrayParams<SharedLibrary>) => {
	return searchEntities(
		sharedLibrariesArray,
		isEntityMatchedByKeywords,
		ACTIVITY.SHARED_LIBRARY_READ,
		canReadyAnyGlobally,
		keywords
	)
}

//#endregion Shared Libraries
