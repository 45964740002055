import MathLive from 'mathlive'
import { Component, CSSProperties } from 'react'
import { getMathlistFontScaleFactor } from '../../utils/mathlive'
import { CopyToClipboardButton } from './CopyToClipboardButton'

export interface InlineMathProps {
	latex: string
	className?: string
	style?: CSSProperties
	/** Whether to automatically scale the fontSize of the inner text. */
	disableFontScaling?: boolean
	/** Whether to enable horizontal overflow scrolling. */
	enableOverflowScrolling?: boolean
	enableCopyToClipboard?: boolean
}

interface InlineMathState {
	fontScaleFactor: number
	/** Used to toggle off/on the MathLive rendered markup, to force a re-render. */
	shouldReRenderMath: boolean
	hasRenderedMath: boolean
}

export default class InlineMath extends Component<InlineMathProps, InlineMathState> {
	wrapper: HTMLSpanElement | null = null

	constructor(props: InlineMathProps) {
		super(props)
		this.state = {
			fontScaleFactor: 1,
			shouldReRenderMath: false,
			hasRenderedMath: false
		}
	}

	setWrapper = (el: HTMLSpanElement | null) => {
		this.wrapper = el
		this.renderMath(this.wrapper)
	}

	componentDidMount() {
		this.renderMath(this.wrapper)
	}

	componentDidUpdate(prevProps: InlineMathProps) {
		if (prevProps.latex !== this.props.latex && this.wrapper) {
			// start re-render (hide wrapper)
			this.setState({
				shouldReRenderMath: true,
				hasRenderedMath: false
			})
		}

		if (this.state.shouldReRenderMath) {
			// finish re-render (show wrapper)
			this.setState({
				shouldReRenderMath: false
			})
		}
	}

	onCreateMathlist = (mathlist: any) => {
		// wrap mathlist (array of MathAtoms) in a "root" atom
		// allows use of mathlive's custom `forEach` method
		const rootAtom = MathLive.MathAtom.makeRoot('math', mathlist)
		const fontScaleFactor = getMathlistFontScaleFactor(rootAtom)
		this.setState({
			fontScaleFactor
		})
	}

	renderMath = (wrapper: HTMLSpanElement | null) => {
		if (wrapper) {
			let shouldRender = false
			// setState while getting the currentState and using a callback for the resulting action.
			// Because setState is asynchronous the status of hasRenderedMath may not be up to date looking directly at state.
			// Use the callback because onCreateMathList also sets state which shouldn't be done while inside setState.
			this.setState(
				currentState => {
					// Decide whether to call renderMathInElement. If it's called twice the sr-only section is duplicated
					shouldRender = !currentState.hasRenderedMath
					return {
						hasRenderedMath: true
					}
				},
				() => {
					if (shouldRender) {
						MathLive.renderMathInElement(this.wrapper, {
							onCreateMathlist: this.onCreateMathlist,
							renderAccessibleContent: 'speakable-text'
						})
					}
				}
			)
		}
	}

	render() {
		const { fontScaleFactor, shouldReRenderMath } = this.state
		if (shouldReRenderMath) {
			return null
		}
		const {
			latex,
			className,
			style,
			disableFontScaling,
			enableOverflowScrolling,
			enableCopyToClipboard
		} = this.props

		const Content = (
			<span
				ref={this.setWrapper}
				className={`InlineMath${className ? ` ${className}` : ''}${
					enableOverflowScrolling ? ' overflow-scroll' : ''
				}${!disableFontScaling ? ` math-scale-${fontScaleFactor}` : ''}`}
				style={style}>
				<script type="math/tex">{latex}</script>
				<span className="visually-hidden" aria-hidden="true">
					{latex}
				</span>
			</span>
		)

		if (!enableCopyToClipboard) return Content

		return (
			<span
				className={`relative${
					style?.display === 'block' || className?.split(' ').includes('db') ? ' db' : ' dib'
				}`}>
				{Content}
				<CopyToClipboardButton text={latex} />
			</span>
		)
	}
}
