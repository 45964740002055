import { Dictionary } from 'lodash'
import { INLINE_FUNCTIONS, INLINE_OPERATORS, INLINE_SYMBOLS } from './mathlive'
import { GREEK_LETTERS } from './variable'

export const NUMERIC_SOLUTION_COMMANDS_GROUPINGS_EXAMPLES = [
	['( )', '(1.25)', '\\left( \\frac{5}{4}\\right)'],
	['\\lgroup \\rgroup', '\\lgroup 1.25\\rgroup', '\\left\\lgroup \\frac{5}{4}\\right\\rgroup'],

	['\\{ \\}', '\\{1.25\\}', '\\left\\{\\frac{5}{4}\\right\\}'],
	['\\lbrace \\rbrace', '\\lbrace 1.25\\rbrace', '\\left\\lbrace \\frac{5}{4}\\right\\rbrace'],

	['[ ]', '[1.25]', '\\left[\\frac{5}{4}\\right]'],
	['\\lbrack \\rbrack', '\\lbrack 1.25\\rbrack', '\\left\\lbrack \\frac{5}{4}}\\right\\rbrack'],

	['| |', '\\left|-1.25\\right|', '\\left|\\frac{-5}{4}\\right|'],
	['\\lvert \\rvert', '\\lvert -1.25\\rvert', '\\left\\lvert \\frac{-5}{4}\\right\\rvert'],
	['\\vert \\vert', '\\vert -1.25\\vert', '\\left\\vert \\frac{-5}{4}\\right\\vert'],

	['\\lfloor \\rfloor', '\\lfloor 1.25\\rfloor', '\\left\\lfloor \\frac{5}{4}\\right\\rfloor'],
	['\\llcorner \\lrcorner', '\\llcorner 1.25\\lrcorner'],

	['\\lceil \\rceil', '\\lceil 1.25\\rceil', '\\left\\lceil \\frac{5}{4}\\right\\rceil'],
	['\\ulcorner \\urcorner', '\\ulcorner 1.25\\urcorner']
]

const NUMERIC_SOLUTION_COMMANDS_GCD_LCM_EXAMPLES = [
	['\\operatorname{gcd}', '\\operatorname{gcd}\\mleft(8,12\\mright)'],
	['\\operatorname{lcm}', '\\operatorname{lcm}\\mleft(8,12\\mright)']
]

/***
 * Inverse trig functions
 * arcsc and arcsec are not listed by mathlive as supported commands, but sympy has methods to calculate them
 * arcctg is listed as supported by mathlive, but latex2sympy doesn't convert it to sympy's arccot correctly
 */
const TRIG_INVERSE_EXAMPLES = [
	// inverse
	['\\arcsin', '\\arcsin \\mleft(1 \\mright)'],
	['\\arccos', '\\arccos \\mleft(1 \\mright)'],
	['\\arctan', '\\arctan \\mleft(1 \\mright)']
	// not supported
	// ['\\arccsc', '\\arccsc \\mleft(1 \\mright)'],
	// ['\\arcsec', '\\arcsec \\mleft(1 \\mright)'],
	// ['\\arcctg', '\\arcctg \\mleft(1 \\mright)']
]

/***
 * Hyperbolic trig functions
 * csch and sech are not listed by mathlive as supported commands, but sympy has methods to calculate them
 * coth is listed as supported by mathlive, but latex2sympy doesn't convert it correctly
 */
const TRIG_HYPERBOLIC_EXAMPLES = [
	// hyperbolic
	['\\sinh', '\\sinh\\mleft(\\pi \\mright)'],
	['\\cosh', '\\cosh\\mleft(\\pi \\mright)'],
	['\\tanh', '\\tanh\\mleft(\\pi \\mright)']
	// not supported
	// ['\\csch', '\\csch\\mleft(\\pi \\mright)'],
	// ['\\sech', '\\sech\\mleft(\\pi \\mright)'],
	// ['\\coth', '\\coth\\mleft(\\pi \\mright)'],
]

/***
 * Currently latex2sympy converts both ar-h and arc-h to use sympy's asinh method so we are treating them like they are the same
 * Some resources suggest ar is for area and arc is for arclength, suggesting they are different
 */
const TRIG_INVERSE_HYPERBOLIC_EXAMPLES = [
	['\\operatorname{arsinh}', '\\operatorname{arsinh}\\mleft(1 \\mright)'],
	['\\operatorname{arcosh}', '\\operatorname{arcosh}\\mleft(1 \\mright)'],
	['\\operatorname{artanh}', '\\operatorname{artanh}\\mleft(1 \\mright)'],
	['\\operatorname{arcsinh}', '\\operatorname{arcsinh}\\mleft(1 \\mright)'],
	['\\operatorname{arccosh}', '\\operatorname{arccosh}\\mleft(1 \\mright)'],
	['\\operatorname{arctanh}', '\\operatorname{arctanh}\\mleft(1 \\mright)']
]

/*** Mathlive commands that are not supported by latex2sympy and have alternatives
 trig
	tg -> tan
	ctg -> cot
	cotg -> cot
	cosec -> csc
 hyperbolic
	sh -> sinh
	ch -> cosh
	th -> tanh
	cth -> coth
 logarithmic
	lg -> log base 10
	lb -> log base 2
*/

const NUMERIC_SOLUTION_COMMANDS_NO_GREEK_EXAMPLES = [
	['+', '1+2'],
	['-', '-1'],
	['*', '1*2'],
	['/', '1/2'],
	['\\lim', '\\lim _{x\\to 3}x'],
	['\\to', '\\lim _{x\\to 3}x'],
	['\\rightarrow', '\\lim _{x\\rightarrow 3}x'],
	['\\Rightarrow', '\\lim _{x\\Rightarrow 3}x'],
	['\\longrightarrow', '\\lim _{x\\longrightarrow 3}x'],
	['\\Longrightarrow', '\\lim _{x\\Longrightarrow 3}x'],
	['\\differentialD', '\\int x\\differentialD x', '\\frac{\\differentialD }{\\differentialD x}x'],
	['\\int', '\\int _{-1}^{2} (9-x^2) \\differentialD x'],
	['\\sum', '\\sum ^{\\infty}_{n = 0} \\frac{1}{n!}'],
	['\\prod', '\\prod _{n = 1}^{10} n^2'],
	['\\log', '\\log 3', '\\log _2{8}', '\\log _{10}3'],
	['\\ln', '\\ln 3'],
	['\\exp', '\\exp \\mleft(3\\mright)', '\\exp \\left(\\sum _{n=1}^2{n}\\right)'],
	['\\exponentialE', '\\exponentialE ^{3}', '\\exponentialE ^{\\left(\\sum _{n=1}^2{n}\\right)}'],
	['\\sqrt', '\\sqrt{8}', '\\sqrt[3]{8}'],
	['\\times', '1.25\\times 10^{-2}'],
	['\\cdot', '1.25\\cdot 0.01'],
	['\\div', '5\\div 4'],
	['\\mod', '5\\mod 4'],
	['\\frac', '\\frac{5}{4}'],
	['\\binom', '\\binom{5}{4}'],
	['\\operatorname{floor}', '\\operatorname{floor}\\mleft(1.25\\mright)'],
	['\\operatorname{ceil}', '\\operatorname{ceil}\\mleft(1.25\\mright)'],
	['\\max', '\\max \\mleft(5,4\\mright)'],
	['\\min', '\\min \\mleft(5,4\\mright)'],
	['\\sin', '\\sin \\mleft(\\pi \\mright)'],
	['\\cos', '\\cos \\mleft(\\pi \\mright)'],
	['\\tan', '\\tan \\mleft(\\pi \\mright)'],
	['\\csc', '\\csc \\mleft(1 \\mright)'],
	['\\sec', '\\sec \\mleft(1 \\mright)'],
	['\\cot', '\\cot \\mleft(1 \\mright)'],
	...TRIG_INVERSE_EXAMPLES,
	...TRIG_HYPERBOLIC_EXAMPLES,
	...TRIG_INVERSE_HYPERBOLIC_EXAMPLES,
	['^', '1\\times 10^2'],
	['^{#?}', '1\\times 10^{2}'],
	[',', '1,234'],
	['.', '1.23'],
	['!', '5!'],
	['\\infty', '\\infty'],
	['\\variable', '\\variable{x}'],
	['\\pi', '\\pi'], // this is the exception of "no greek", since it is a constant
	['\\emptyset', '\\emptyset'],
	['\\imaginaryI', '\\imaginaryI'],
	['\\imaginaryJ', '\\imaginaryJ'],
	['\\degree', '\\degree'],
	['\\angle', '\\angle'],
	['\\operatorname{Re}', '\\operatorname{Re}\\mleft(1+2\\imaginaryI\\mright)=1'],
	['\\operatorname{Im}', '\\operatorname{Im}\\mleft(1+2\\imaginaryI\\mright)=2'],
	['\\operatorname{Arg}', '\\operatorname{Arg}\\mleft(1\\angle 2\\mright)=2'],
	['\\operatorname{Abs}', '\\operatorname{Abs}\\mleft(1\\angle 2\\mright)=1'],
	['\\operatorname{conj}', '\\operatorname{conj}\\mleft(1+2imaginaryI\\mright)=1-2\\imaginaryI ']
]

export const NUMERIC_SOLUTION_COMMANDS_NO_GREEK = [
	//commands without examples
	'=',
	':',
	';',
	'_',
	'_{#?}',
	'\\operatorname',
	//commands that support matrices, even though matrix is not allowed yet for numeric solutions
	'&',
	'\\\\',
	'\\begin',
	'\\end',
	//derive remaining commands from the examples
	...NUMERIC_SOLUTION_COMMANDS_NO_GREEK_EXAMPLES.map(v => v[0]),
	...NUMERIC_SOLUTION_COMMANDS_GCD_LCM_EXAMPLES.map(v => v[0]),
	...NUMERIC_SOLUTION_COMMANDS_GROUPINGS_EXAMPLES.reduce((results, v) => {
		const [left, right] = v[0].split(' ')
		results.push(left)
		if (left !== right) results.push(right)
		return results
	}, [])
]

export const NUMERIC_ANSWER_COMMANDS = ['-', '^', '^{#?}', '\\times', '\\infty', '\\emptyset']

export const COMPLEX_NUMERIC_ANSWER_COMMANDS = [
	...NUMERIC_ANSWER_COMMANDS,
	'+',
	'\\imaginaryI',
	'\\imaginaryJ',
	'\\degree',
	'\\angle',
	'\\pi',
	'\\frac'
]

export const UNIT_COMMANDS = [
	'-',
	'^',
	'^{#?}',
	'\\times',
	'\\cdot',
	'*',
	'\\frac',
	'\\degree',
	'\\%',
	'\\:',
	'\\mu',
	'\\Omega',
	'\\pi',
	...NUMERIC_SOLUTION_COMMANDS_GROUPINGS_EXAMPLES.reduce((results, v) => {
		const [left, right] = v[0].split(' ')
		results.push(left)
		if (left !== right) results.push(right)
		return results
	}, [])
]

export const NUMERIC_SOLUTION_COMMANDS = [
	...NUMERIC_SOLUTION_COMMANDS_NO_GREEK,
	// Supported Greek letters for variables that are converted to LaTeX
	...Object.values(GREEK_LETTERS).map(v => v.command)
]

export const VARIABLE_COMMANDS = [
	',',
	'_',
	'_{#?}',
	// Supported Greek letters for variables that are converted to LaTeX
	...Object.values(GREEK_LETTERS).map(v => v.command)
]

/** List of style commands that are available in MathLive but are unsupported in our numeric formula inputs*/
export const STYLE_COMMANDS = [
	// Size
	'\\tiny',
	'\\scriptsize',
	'\\footnotesize',
	'\\small',
	'\\normalsize',
	'\\large',
	'\\Large',
	'\\LARGE',
	'\\huge',
	'\\Huge',

	// Series: weight
	'\\fontseries',
	'\\bf',
	'\\bm',
	'\\bold',
	'\\mathbf',
	'\\boldsymbol',
	'\\bfseries',
	'\\textbf',
	'\\mathmd',
	'\\mdseries',
	'\\textmd',

	// Shape: italic, small caps
	'\\fontshape',
	'\\it',
	'\\mathit',
	'\\upshape',
	'\\textup',
	'\\textit',
	'\\slshape',
	'\\textsl',
	'\\scshape',
	'\\textsc',

	// Font Family
	'\\fontfamily',
	'\\mathrm',
	'\\rmfamily',
	'\\textrm',
	'\\mathsf',
	'\\sffamily',
	'\\textsf',
	'\\mathtt',
	'\\ttfamily',
	'\\texttt',
	'\\Bbb',
	'\\mathbb',
	'\\frak',
	'\\mathfrak',
	'\\mathcal',
	'\\mathscr',

	'\\textnormal',
	'\\mbox',
	'\\text',
	'\\class',
	'\\cssId',
	'\\em',
	'\\emph',

	'\\textlf',

	// Decoration
	'\\color',
	'\\textcolor',
	'\\boxed',
	'\\colorbox',
	'\\fcolorbox',
	'\\bbox'
]

const ADDITIONAL_EXPRESSION_COMMANDS_EXAMPLES = [
	['\\overline', '2\\overline{x}'],
	['\\bar', '2\\bar{x}'],
	['<', '0<1'],
	['\\leq', '0\\leq 1'],
	['\\le', '0\\le 1'],
	['>', '1>0'],
	['\\geq', '1\\ge 0'],
	['\\ge', '1\\ge 0'],
	['!=', '0 != 1'],
	['\\ne', '0\\ne 1'],
	['\\neq', '0\\neq 1'],
	['\\$', '\\$1,234'],
	['\\%', '12.34\\%']
]

// support all numeric commands except `gcd()` and `lcm()`
export const EXPRESSION_COMMANDS = [
	...NUMERIC_SOLUTION_COMMANDS.filter(command => !['\\operatorname{gcd}', '\\operatorname{lcm}'].includes(command)),
	...ADDITIONAL_EXPRESSION_COMMANDS_EXAMPLES.map(v => v[0])
]

/** Commands that have dual "{}" groups, e.g. `\frac{}{}` */
export const DUAL_GROUP_COMMANDS = [
	'\\frac',
	'\\dfrac',
	'\\cfrac',
	'\\tfrac',
	'\\binom',
	'\\dbinom',
	'\\tbinom',
	'\\pdiff',
	'\\stackrel',
	'\\stackbin',
	'\\underset',
	'\\overset'
]

/**
 * Delimiter Pairs that are visible when displayed and can be modified using `\left`+`\right` and `\mleft`+`\mright`
 */
const MODIFIABLE_VISIBLE_DELIMITER_PAIRS: Dictionary<string> = {
	'(': ')',
	'[': ']',
	'\\lbrack': '\\rbrack',
	'\\{': '\\}',
	'\\lbrace': '\\rbrace',
	'\\langle': '\\rangle',
	'\\lfloor': '\\rfloor',
	'\\llcorner': '\\lrcorner',
	'\\lceil': '\\rceil',
	'\\ulcorner': '\\urcorner',
	'|': '|',
	'\\vert': '\\vert',
	'\\lvert': '\\rvert',
	'\\|': '\\|',
	'\\Vert': '\\Vert',
	'\\lVert': '\\rVert',
	'\\lgroup': '\\rgroup',
	'\\lmoustache': '\\rmoustache'
}

/**
 * Delimiter pairs that are visible when displayed.
 *
 * Used when replacing variable LaTeX, to know if values are already wrapped in delimiters.
 */
export const VISIBLE_DELIMITER_PAIRS: Dictionary<string> = {
	// add different possible versions of modifiable pairs
	...Object.keys(MODIFIABLE_VISIBLE_DELIMITER_PAIRS).reduce((newPairs: Dictionary<string>, left) => {
		const right = MODIFIABLE_VISIBLE_DELIMITER_PAIRS[left]
		newPairs[left] = right
		newPairs[`\\left${left}`] = `\\right${right}`
		newPairs[`\\mleft${left}`] = `\\mright${right}`
		return newPairs
	}, {})
}

/**
 * All possible delimiter pairs, both visible and invisible.
 *
 * Used when auto-fixing LaTeX by closing un-closed delimiters.
 */
export const DELIMITER_PAIRS: Dictionary<string> = {
	...VISIBLE_DELIMITER_PAIRS,
	'{': '}'
}

export const DELIMITER_PAIRS_INVERTED: Dictionary<string> = {
	// add different possible versions of modifiable pairs
	...Object.keys(DELIMITER_PAIRS).reduce((newPairs: Dictionary<string>, left) => {
		const right = DELIMITER_PAIRS[left]
		newPairs[right] = left
		return newPairs
	}, {})
}

export const DELIMITER_SYMBOLS = [...Object.keys(DELIMITER_PAIRS), ...Object.values(DELIMITER_PAIRS)]

export const ITERATOR_COMMANDS = [
	{ latex: '\\sum', subOp: '=' },
	{ latex: '\\prod', subOp: '=' },
	{ latex: '\\lim', subOp: '\\to' }
]

export const NUMERIC_SUPPORTED_NON_VARIABLE_SYMBOLS: string[] = [
	'E' // E for e-notation
]

//#region Examples

const LATEX_EXAMPLES_GREEK = Object.values(GREEK_LETTERS).map(({ command, text }) => [command])

export const LATEX_EXAMPLES_NUMERIC_SOLUTION = [
	...NUMERIC_SOLUTION_COMMANDS_NO_GREEK_EXAMPLES,
	...NUMERIC_SOLUTION_COMMANDS_GCD_LCM_EXAMPLES,
	...NUMERIC_SOLUTION_COMMANDS_GROUPINGS_EXAMPLES
]

// expression commands include everything in numeric except for GCD and LCM, plus additional commands and greek letters
export const LATEX_EXAMPLES_EXPRESSION_SOLUTION = [
	...NUMERIC_SOLUTION_COMMANDS_NO_GREEK_EXAMPLES,
	...ADDITIONAL_EXPRESSION_COMMANDS_EXAMPLES,
	...LATEX_EXAMPLES_GREEK,
	...NUMERIC_SOLUTION_COMMANDS_GROUPINGS_EXAMPLES
]

export const LATEX_EXAMPLES_NUMERIC_ANSWER = [
	...NUMERIC_SOLUTION_COMMANDS_NO_GREEK_EXAMPLES.filter(v => COMPLEX_NUMERIC_ANSWER_COMMANDS.includes(v[0]))
]

/** use other inline mathlive shortcuts to find any additional latex commands that may only be used in text areas of the app */
const ADDITIONAL_PROMPT_EXAMPLES = [
	...Object.values(
		Object.assign({}, INLINE_FUNCTIONS, INLINE_OPERATORS, INLINE_OPERATORS, INLINE_SYMBOLS) as Dictionary<
			string | { mode: string; value: string }
		>
	)
]
	.reduce((results, v) => {
		const latex = typeof v === 'string' ? v : v.value
		if (
			!EXPRESSION_COMMANDS.includes(latex) &&
			//prevent duplicates
			!results.includes(latex) &&
			//exclude other commands that are covered
			!['\\operatorname{gcd}', '\\operatorname{lcm}', '\\sum_{#?}^{#?}', '\\prod_{#?}^{#?}'].includes(latex)
		)
			results.push(latex)
		return results
	}, [] as string[])
	.map(v => [v])

const LATEX_EXAMPLES_STYLES = [
	// color
	['\\textcolor', '\\textcolor{#FF0000}{azAZ123}'],
	// size
	['\\tiny', '\\tiny azAZ123'],
	['\\scriptsize', '\\scriptsize azAZ123'],
	['\\footnotesize', '\\footnotesize azAZ123'],
	['\\small', '\\small azAZ123'],
	['\\normalsize', '\\normalsize azAZ123'],
	['\\large', '\\large azAZ123'],
	['\\Large', '\\Large azAZ123'],
	['\\LARGE', '\\LARGE azAZ123'],
	['\\huge', '\\huge azAZ123'],
	['\\Huge', '\\Huge azAZ123'],
	// font family
	['\\mathit', '\\mathit{azAZ123}'],
	['\\mathcal', '\\mathcal{azAZ123}'],
	['\\mathfrak', '\\mathfrak{azAZ123}'],
	['\\mathbb', '\\mathbb{azAZ123}'],
	['\\mathscr', '\\mathscr{azAZ123}'],
	['\\mathrm', '\\mathrm{azAZ123}'],
	['\\mathtt', '\\mathtt{azAZ123}'],
	['\\mathsf', '\\mathsf{azAZ123}'],
	// shape - weight
	['\\fontshape{n}', '\\fontshape{n} azAZ123'],
	['\\fontshape{it}', '\\fontshape{it} azAZ123'],
	['\\fontshape{sl}', '\\fontshape{sl} azAZ123'],
	['\\fontshape{sc}', '\\fontshape{sc} azAZ123'],
	['\\fontshape{ol}', '\\fontshape{ol} azAZ123']
]

export const LATEX_EXAMPLES_PROMPT = [
	//commands that are not supported by our math engine but are supported by mathlive, and can only be used in text areas for now
	['{matrix}', '\\begin{matrix}1 & 2 \\\\ 3 & 4\\end{matrix}'],
	...ADDITIONAL_PROMPT_EXAMPLES,
	...LATEX_EXAMPLES_GREEK,
	...LATEX_EXAMPLES_STYLES
]

//#endregion Examples
