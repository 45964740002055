import * as Sentry from '@sentry/react'
import { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { LOCKDOWN_BROWSER_PATH } from 'studiokit-scaffolding-js'
import { ErrorBoundary } from 'studiokit-scaffolding-js/lib/components/ErrorBoundary'
import { Loading } from 'studiokit-scaffolding-js/lib/components/Loading'
import NewVersionAlert from 'studiokit-scaffolding-js/lib/components/NewVersionAlert'
import Notifications from 'studiokit-scaffolding-js/lib/components/Notifications'
import { setupMobileEventListeners } from 'studiokit-scaffolding-js/lib/utils/dom'
import { getLockDownBrowserInfo } from 'studiokit-scaffolding-js/lib/utils/lockDownBrowser'
import CONFIG from '../configuration'
import { ReduxState } from '../types/ReduxState'
import Footer from './Footer'
import Header from './Header'
import KeyboardHandler from './KeyboardHandler'
import Routes from './Routes'

export interface AppProps {
	isAppInitialized: boolean
}

export class App extends Component<AppProps & RouteComponentProps> {
	componentDidMount() {
		setupMobileEventListeners()
	}

	render() {
		const { isAppInitialized, location } = this.props
		const lockDownBrowserInfo = getLockDownBrowserInfo()
		const isLandingPage = location.pathname.includes('login')
		const isLtiLaunchPage = location.pathname.includes('lti-launch')
		const isLockDownBrowserRoute =
			location.pathname.includes(LOCKDOWN_BROWSER_PATH.LAUNCH) ||
			location.pathname.includes(LOCKDOWN_BROWSER_PATH.CHECK)
		// Header should be decorative (non-functional) when viewing LTI Launch or inside LockDown Browser
		const isHeaderDecorative =
			isLockDownBrowserRoute || isLtiLaunchPage || lockDownBrowserInfo.isClientLockDownBrowser
		// Don't show footer when inside LockDownBrowser or viewing LTI Launch inside an iFrame
		const isFooterVisible =
			!lockDownBrowserInfo.isClientLockDownBrowser &&
			!isLockDownBrowserRoute &&
			(!isLtiLaunchPage || window === window.parent)

		return (
			<>
				<Helmet
					defaultTitle="Variate"
					titleTemplate="Variate - %s"
					meta={[
						{ name: 'description', content: 'Variate - Purdue' },
						{ property: 'og:type', content: 'article' }
					]}
					link={[]}
				/>
				<a className="skip-main" href="#main">
					Skip to content
				</a>
				{!isLandingPage && <Header isDecorative={isHeaderDecorative} />}
				<ErrorBoundary>
					<NewVersionAlert />
					<main id="main">{!isAppInitialized ? <Loading /> : <Routes />}</main>
					<Notifications />
				</ErrorBoundary>
				{isFooterVisible && <Footer useDarkBackground={isLandingPage} />}
				<KeyboardHandler />
			</>
		)
	}
}

const mapStateToProps = (state: ReduxState) => {
	return {
		isAppInitialized: CONFIG.IS_DOWNTIME || state.auth.isInitialized
	}
}

const WrappedApp = connect(mapStateToProps)(Sentry.withProfiler(App))
export default WrappedApp
