import { Dictionary } from 'lodash'
import { DeltaStatic } from 'quill'
import { Artifact, Model } from 'studiokit-scaffolding-js/lib/types'
import { Choice, ChoiceRequestModel, ChoiceState, ChoiceStateDiff } from './Choice'
import { DiffObject } from './DiffObject'
import { NUMBER_FORMAT_TYPE } from './NumberFormatType'
import { Response, ResponseRequestModel, ResponseState, ResponseStateDiff } from './Response'

export enum RESPONSE_TYPE {
	NUMERIC = 'Numeric',
	EXPRESSION_SYMBOLIC = 'ExpressionSymbolic',
	EXPRESSION_SYMBOLIC_FORM = 'ExpressionSymbolicForm',
	MULTIPLE_CHOICE = 'MultipleChoice'
}

export const ExpressionResponseTypes = [RESPONSE_TYPE.EXPRESSION_SYMBOLIC, RESPONSE_TYPE.EXPRESSION_SYMBOLIC_FORM]

export enum TOLERANCE_TYPE {
	UNIT = 'Unit',
	PERCENTAGE = 'Percentage'
}

export const getNameForToleranceType = (type: TOLERANCE_TYPE) => {
	switch (type) {
		case TOLERANCE_TYPE.UNIT:
			return 'Absolute'
		default:
			return type
	}
}

interface BaseSolution extends Model {
	id: number
	ordinal: number
	label: string | null
	responseType: RESPONSE_TYPE | null
	canBePublished: boolean
	choicesCount: number
}

/** Match problem and statement structure */
export interface SparseSolution extends BaseSolution {}

export interface Solution extends BaseSolution {
	statementId: number
	prefix: string | null
	suffix: string | null
	canArtifactBePublished: boolean | null
	dateStored: string
	dateLastUpdated: string
	numberFormatType: NUMBER_FORMAT_TYPE | null
	lockChoiceOrder: boolean
	choices: Choice[]
	incorrectFeedbackArtifact?: Artifact
	responses: Response[]
}

/**
 * Used for CreateOrEdit state
 */
export interface SolutionState {
	id?: number
	/** for fallback loop "key" when id is undefined */
	guid?: string
	responseType: RESPONSE_TYPE | null
	prefix: string | null
	suffix: string | null
	numberFormatType: NUMBER_FORMAT_TYPE | null
	lockChoiceOrder: boolean
	choices: ChoiceState[]
	responses: ResponseState[]
	artifactUrl?: string
	/** for incorrect feedbackArtifact */
	quillContents: DeltaStatic | undefined | null
}

/**
 * Used for ChangeSummaryModal diff
 */
export interface SolutionStateDiff {
	id?: DiffObject<number | undefined>
	guid?: DiffObject<string | undefined>
	responseType?: DiffObject<RESPONSE_TYPE | null>
	prefix?: DiffObject<string | null>
	suffix?: DiffObject<string | null>
	numberFormatType?: DiffObject<NUMBER_FORMAT_TYPE | null>
	lockChoiceOrder?: DiffObject<boolean | undefined>
	choices?: Dictionary<ChoiceStateDiff | DiffObject<ChoiceState | undefined>>
	responses?: Dictionary<ResponseStateDiff | DiffObject<ResponseState | undefined>>
	artifactUrl?: DiffObject<string | undefined>
	quillContents?: DiffObject<DeltaStatic | undefined | null>
}

/**
 * Used for API POST/PUT
 */
export interface SolutionRequestModel
	extends Omit<SolutionState, 'guid' | 'choices' | 'responses' | 'quillContents' | 'artifactUrl'> {
	choices: ChoiceRequestModel[]
	responses: ResponseRequestModel[]
	incorrectFeedbackArtifactContent?: string | null
	incorrectFeedbackArtifactContentVariableNames?: string[] | null
}
