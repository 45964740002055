import { ComponentType } from 'react'
import { RouteComponentProps, Switch, withRouter } from 'react-router'
import { BASE_ACTIVITY, Group, LOCKDOWN_BROWSER_PATH, ModelCollection } from 'studiokit-scaffolding-js'
import ActivityRequiredComponent from 'studiokit-scaffolding-js/lib/components/HOC/ActivityRequiredComponent'
import AsyncComponent from 'studiokit-scaffolding-js/lib/components/HOC/AsyncComponent'
import CollectionComponent from 'studiokit-scaffolding-js/lib/components/HOC/CollectionComponent'
import CollectionItemComponent from 'studiokit-scaffolding-js/lib/components/HOC/CollectionItemComponent'
import DataDependentComponent from 'studiokit-scaffolding-js/lib/components/HOC/DataDependentComponent'
import EntityComponent, {
	EntityComponentWrappedHeaderProps
} from 'studiokit-scaffolding-js/lib/components/HOC/EntityComponent'
import ModelErrorRedirectComponent from 'studiokit-scaffolding-js/lib/components/HOC/ModelErrorRedirectComponent'
import SearchPersistorComponent from 'studiokit-scaffolding-js/lib/components/HOC/SearchPersistorComponent'
import UserComponent from 'studiokit-scaffolding-js/lib/components/HOC/UserComponent'
import Route from 'studiokit-scaffolding-js/lib/components/SentryRoute'
import {
	ActivityOptions,
	canPerformActivityGlobally,
	canPerformActivityGloballyOrOnEntity,
	canPerformActivityGloballyOrOnSomeEntities
} from 'studiokit-scaffolding-js/lib/utils/baseActivity'
import { groupsAsAnythingButLearner } from 'studiokit-scaffolding-js/lib/utils/groupRoles'
import CONFIG from '../../configuration'
import ACTIVITY from '../../constants/activity'
import { Assessment } from '../../types/Assessment'
import { Problem } from '../../types/Problem'
import { ReduxState } from '../../types/ReduxState'
import { SharedLibrary } from '../../types/SharedLibrary'
import { VariateGroup } from '../../types/VariateGroup'
import { ProblemManageProps, ProblemManageReduxProps, SharedLibrarySearch } from '../Problem/Manage'
import { SearchResultsContextManager } from '../Shared/SearchResults/SearchResultsContextManager'

//#region Common

const Downtime = AsyncComponent(() => import('../Downtime'))
const Home = UserComponent(
	CollectionComponent(
		AsyncComponent(() => import('../Home')),
		'home'
	)
)
const ErrorScreen = AsyncComponent(() => import('studiokit-scaffolding-js/lib/components/Error'))
const NotFound = AsyncComponent(() => import('studiokit-scaffolding-js/lib/components/NotFound'))
const LoginRoutes = AsyncComponent(() => import('../Routes/Login'))
const PrivacyPolicy = AsyncComponent(() => import('../PrivacyPolicy'))
const Help = UserComponent(AsyncComponent(() => import('../Help')))
const LockDownBrowserHelp = UserComponent(AsyncComponent(() => import('../Help/LockDownBrowser')))
const LockDownBrowserLaunch = AsyncComponent(
	() => import('studiokit-scaffolding-js/lib/components/LockDownBrowser/Launch')
)
const LockDownBrowserCheck = AsyncComponent(
	() => import('studiokit-scaffolding-js/lib/components/LockDownBrowser/Check')
)

//#endregion Common

//#region Admins

const AdminManage = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../GlobalUserRoles/Admin')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_ROLE_READ_ANY
	)
)

const CreatorManage = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../GlobalUserRoles/Creator')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_ROLE_MODIFY_ANY
	)
)

const AssessmentCreatorManage = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../GlobalUserRoles/AssessmentCreator')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_ROLE_MODIFY_ANY
	)
)

const ProblemCreatorManage = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../GlobalUserRoles/ProblemCreator')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_ROLE_MODIFY_ANY
	)
)

const AIProblemCreatorManage = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../GlobalUserRoles/AIProblemCreator')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_ROLE_MODIFY_ANY
	)
)

const Cache = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../Cache')),
		canPerformActivityGlobally,
		// currently only GAs are cached
		// if other entities are cached, consider changing to a generic activity,
		// or add other entity specific activities here.
		ACTIVITY.GROUP_ASSESSMENT_CACHE_PURGE_ANY
	)
)

//#endregion Admins

//#region Users

const UserManage = UserComponent(
	ActivityRequiredComponent(
		SearchPersistorComponent(
			CollectionComponent(
				AsyncComponent(() => import('../User/Manage')),
				'search.users'
			),
			'UsersManage'
		),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_READ_ANY
	)
)

const User = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(ModelErrorRedirectComponent(AsyncComponent(() => import('../User'))), 'search.users'),
		canPerformActivityGlobally,
		BASE_ACTIVITY.USER_READ_ANY
	)
)

const UserRoles = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(
			ModelErrorRedirectComponent(AsyncComponent(() => import('../User/Roles'))),
			'search.users'
		),
		(requiredActivity, options) =>
			canPerformActivityGlobally(BASE_ACTIVITY.USER_ROLE_READ_ANY, options) &&
			canPerformActivityGlobally(BASE_ACTIVITY.USER_READ_ANY, options),
		''
	)
)

//#endregion Users

//#region Assessments

const AssessmentManage = UserComponent(
	DataDependentComponent(
		ActivityRequiredComponent(
			SearchPersistorComponent(
				CollectionComponent(
					AsyncComponent(() => import('../Assessment/Manage')),
					'search.assessments'
				),
				'AssessmentsManage'
			),
			(requiredActivity: string, options: ActivityOptions) =>
				canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.ASSESSMENT_READ, options) ||
				canPerformActivityGlobally(ACTIVITY.ASSESSMENT_CREATE, options),
			'',
			'assessments'
		),
		// wait to render children until assessments have been loaded initially
		// so that ActivityRequiredComponent can check for activities on assessments
		(state: ReduxState) => !!state.models?.assessments?._metadata?.fetchedAt
	)
)

const AssessmentCreate = UserComponent(
	ActivityRequiredComponent(
		SearchResultsContextManager(
			CollectionItemComponent(
				ModelErrorRedirectComponent(AsyncComponent(() => import('../Assessment/CreateOrEdit'))),
				'assessments'
			)
		),
		canPerformActivityGlobally,
		ACTIVITY.ASSESSMENT_CREATE,
		''
	)
)

const AssessmentRoutes = UserComponent(
	DataDependentComponent(
		EntityComponent<Assessment, 'assessment'>(
			ActivityRequiredComponent(
				AsyncComponent(() => import('./Assessment')),
				canPerformActivityGloballyOrOnEntity,
				ACTIVITY.ASSESSMENT_READ,
				'assessment',
				// if individual access is revoked, redirect to the manage route
				(_options: ActivityOptions) => '/assessments'
			),
			AsyncComponent(
				() =>
					import('../Assessment/Header') as Promise<{
						default: ComponentType<EntityComponentWrappedHeaderProps<Assessment, 'assessment'>>
					}>
			),
			ACTIVITY.ASSESSMENT_READ,
			'assessments',
			'assessment'
		),
		// wait to render children until assessments have been loaded initially
		// so that EntityComponent will apply the "search" prefix correctly
		(state: ReduxState) => !!state.models?.assessments?._metadata?.fetchedAt
	)
)

//#endregion Assessments

//#region Problems

const ProblemManage = UserComponent(
	DataDependentComponent(
		ActivityRequiredComponent(
			CollectionComponent(
				SearchPersistorComponent<
					Omit<ProblemManageProps, keyof ProblemManageReduxProps | keyof RouteComponentProps>,
					SharedLibrarySearch
				>(
					AsyncComponent(() => import('../Problem/Manage')),
					'ProblemsManage'
				),
				'search.problems'
			),
			(requiredActivity: string, options: ActivityOptions) =>
				canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.PROBLEM_READ, options) ||
				canPerformActivityGlobally(ACTIVITY.PROBLEM_CREATE, options),
			'',
			'problems'
		),
		// wait to render children until problems have been loaded initially
		// so that ActivityRequiredComponent can check for activities on problems
		(state: ReduxState) => !!state.models?.problems?._metadata?.fetchedAt
	)
)

const ProblemCreate = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(
			ModelErrorRedirectComponent(AsyncComponent(() => import('../Problem/CreateOrEdit'))),
			'problems'
		),
		canPerformActivityGlobally,
		ACTIVITY.PROBLEM_CREATE,
		''
	)
)

const ProblemRoutes = UserComponent(
	DataDependentComponent(
		EntityComponent<Problem, 'problem'>(
			ActivityRequiredComponent(
				AsyncComponent(() => import('./Problem')),
				canPerformActivityGloballyOrOnEntity,
				ACTIVITY.PROBLEM_READ,
				'problem',
				// if individual access is revoked, redirect to the manage route
				(_options: ActivityOptions) => '/problems'
			),
			AsyncComponent(() => import('../Problem/Header')),
			ACTIVITY.PROBLEM_READ,
			'problems',
			'problem'
		),
		// wait to render children until problems have been loaded initially
		// so that EntityComponent will apply the "search" prefix correctly
		(state: ReduxState) => !!state.models?.problems?._metadata?.fetchedAt
	)
)

//#endregion Problems

//#region Groups

const canAccessGroupManage = (options: ActivityOptions) =>
	(!!options.entities && groupsAsAnythingButLearner(options.entities as ModelCollection<Group>).length > 0) ||
	canPerformActivityGlobally(BASE_ACTIVITY.GROUP_READ, options) ||
	canPerformActivityGlobally(BASE_ACTIVITY.GROUP_CREATE, options)

const GroupManage = UserComponent(
	DataDependentComponent(
		ActivityRequiredComponent(
			SearchPersistorComponent(
				CollectionComponent(
					AsyncComponent(() => import('../Group/Manage')),
					'search.groups'
				),
				'GroupManage'
			),
			(_requiredActivity, options) => canAccessGroupManage(options),
			'',
			'groups'
		),
		// wait to render children until groups have been loaded initially
		// so that ActivityRequiredComponent can check for activities on groups
		(state: ReduxState) => !!state.models?.groups?._metadata?.fetchedAt
	)
)

const GroupCreate = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(
			AsyncComponent(() => import('../Group/CreateOrEdit')),
			'groups'
		),
		canPerformActivityGlobally,
		BASE_ACTIVITY.GROUP_CREATE
	)
)

const GroupRoutes = UserComponent(
	DataDependentComponent(
		EntityComponent<VariateGroup, 'group'>(
			ActivityRequiredComponent(
				AsyncComponent(() => import('./Group')),
				canPerformActivityGloballyOrOnEntity,
				BASE_ACTIVITY.GROUP_READ,
				'group',
				// if individual access is revoked, redirect to the manage route, otherwise homepage
				(options: ActivityOptions) => (canAccessGroupManage(options) ? '/courses' : '/')
			),
			AsyncComponent(() => import('../Group/Header')),
			BASE_ACTIVITY.GROUP_READ,
			'groups',
			'group'
		),
		// wait to render children until groups have been loaded initially
		// so that EntityComponent will apply the "search" prefix correctly
		(state: ReduxState) => !!state.models?.groups?._metadata?.fetchedAt
	)
)

//#endregion Groups

//#region SharedLibraries

const SharedLibraryManage = UserComponent(
	DataDependentComponent(
		ActivityRequiredComponent(
			SearchPersistorComponent(
				CollectionComponent(
					AsyncComponent(() => import('../SharedLibrary/Manage')),
					'search.sharedLibraries'
				),
				'SharedLibrariesManage'
			),
			(requiredActivity: string, options: ActivityOptions) =>
				canPerformActivityGloballyOrOnSomeEntities(ACTIVITY.SHARED_LIBRARY_READ, options) ||
				canPerformActivityGlobally(ACTIVITY.SHARED_LIBRARY_CREATE, options),
			'',
			'sharedLibraries'
		),
		// wait to render children until sharedLibraries have been loaded initially
		// so that ActivityRequiredComponent can check for activities on sharedLibraries
		(state: ReduxState) => !!state.models?.sharedLibraries?._metadata?.fetchedAt
	)
)

const SharedLibraryCreate = UserComponent(
	ActivityRequiredComponent(
		CollectionItemComponent(
			ModelErrorRedirectComponent(AsyncComponent(() => import('../SharedLibrary/CreateOrEdit'))),
			'sharedLibraries'
		),
		canPerformActivityGlobally,
		ACTIVITY.SHARED_LIBRARY_CREATE,
		''
	)
)

const SharedLibraryRoutes = UserComponent(
	DataDependentComponent(
		EntityComponent<SharedLibrary, 'sharedLibrary'>(
			ActivityRequiredComponent(
				AsyncComponent(() => import('./SharedLibrary')),
				canPerformActivityGloballyOrOnEntity,
				ACTIVITY.SHARED_LIBRARY_READ,
				'sharedLibrary',
				// if individual access is revoked, redirect to the manage route
				(_options: ActivityOptions) => '/sharedLibraries'
			),
			AsyncComponent(() => import('../SharedLibrary/Header')),
			ACTIVITY.SHARED_LIBRARY_READ,
			'sharedLibraries',
			'sharedLibrary'
		),
		// wait to render children until sharedLibraries have been loaded initially
		// so that EntityComponent will apply the "search" prefix correctly
		(state: ReduxState) => !!state.models?.sharedLibraries?._metadata?.fetchedAt
	)
)

//#endregion SharedLibraries

//#region Lti Launch

const LtiLaunch = UserComponent(
	ActivityRequiredComponent(
		AsyncComponent(() => import('../Lti/LaunchPresenter')),
		canPerformActivityGlobally,
		BASE_ACTIVITY.LTI_LAUNCH_READ_OWN
	)
)

//#endregion Lti Launch

const Routes = () =>
	CONFIG.IS_DOWNTIME ? (
		<Switch>
			<Route component={Downtime} />
		</Switch>
	) : (
		<Switch>
			<Route exact path="/" component={Home} />

			<Route exact path="/admins" component={AdminManage} />
			<Route exact path="/creators" component={CreatorManage} />
			<Route exact path="/problem-creators" component={ProblemCreatorManage} />
			<Route exact path="/ai-problem-creators" component={AIProblemCreatorManage} />
			<Route exact path="/assessment-creators" component={AssessmentCreatorManage} />
			<Route exact path="/cache" component={Cache} />

			<Route exact path="/users" render={() => <UserManage disableAutoLoad />} />
			<Route exact path="/users/:userId" component={User} />
			<Route exact path="/users/:userId/roles" component={UserRoles} />

			<Route exact path="/assessments" render={() => <AssessmentManage disableAutoLoad />} />
			<Route exact path="/assessments/new" component={AssessmentCreate} />
			<Route path="/assessments/:assessmentId" component={AssessmentRoutes} />

			<Route exact path="/problems" render={() => <ProblemManage disableAutoLoad />} />
			<Route exact path="/problems/new" component={ProblemCreate} />
			<Route path="/problems/:problemId" component={ProblemRoutes} />

			<Route exact path="/courses" render={() => <GroupManage disableAutoLoad />} />
			<Route exact path="/courses/new" component={GroupCreate} />
			<Route path="/courses/:groupId" component={GroupRoutes} />

			<Route exact path="/sharedLibraries" render={() => <SharedLibraryManage disableAutoLoad />} />
			<Route exact path="/sharedLibraries/new" component={SharedLibraryCreate} />
			<Route path="/sharedLibraries/:sharedLibraryId" component={SharedLibraryRoutes} />

			<Route path="/lti-launch/:ltiLaunchId" component={LtiLaunch} />

			<Route path="/login" component={LoginRoutes} />
			<Route exact path="/privacy-policy" component={PrivacyPolicy} />
			<Route exact path="/error" component={ErrorScreen} />
			<Route exact path="/not-found" component={NotFound} />
			<Route exact path="/downtime" component={Downtime} />

			<Route exact path="/help" component={Help} />
			<Route exact path="/help/lockdown-browser" component={LockDownBrowserHelp} />
			<Route exact path={`/${LOCKDOWN_BROWSER_PATH.LAUNCH}`} component={LockDownBrowserLaunch} />
			<Route exact path={`/${LOCKDOWN_BROWSER_PATH.CHECK}`} component={LockDownBrowserCheck} />

			{/* fallback to not found */}
			<Route component={NotFound} />
		</Switch>
	)

const WrappedRoutes = withRouter(Routes)
export default WrappedRoutes
