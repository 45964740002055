import { Decimal } from 'decimal.js'
import update from 'immutability-helper'
import { Dictionary, isEqual, uniq } from 'lodash'
import { Quill } from 'react-quill'
import { v4 as uuidv4 } from 'uuid'
import InlineMath from '../components/Shared/InlineMath'
import { DUAL_GROUP_COMMANDS, NUMERIC_SOLUTION_COMMANDS, VISIBLE_DELIMITER_PAIRS } from '../constants/commands'
import { SOLUTION_ENGINE_ERROR_TYPE } from '../constants/solutionEngineErrorType'
import { VALIDITY_LEVEL } from '../constants/validity'
import {
	adjacentLatexVariableRegEx,
	defaultCalculatedVariableParameters,
	defaultRandomVariableParameters,
	latexENotationRegEx,
	latexFractionRegEx,
	latexSciNotationRegEx,
	latexVariableAfterDigitRegEx,
	latexVariableBeforeDigitRegEx,
	latexVariableRegEx,
	variableNameRegEx,
	variableNameUnwrappedSingleSubRegEx,
	variableNameWrappedSingleSubRegEx
} from '../constants/variable'
import { DiffObject } from '../types/DiffObject'
import { NUMBER_FORMAT_TYPE } from '../types/NumberFormatType'
import {
	CalculatedValueVariableParameters,
	CalculatedValueVariableState,
	NumberSetVariableState,
	RandomNumberVariableParameters,
	RandomNumberVariableState,
	RichTextVariableState,
	Variable,
	VARIABLE_TYPE,
	VariableBaseModel,
	VariableState
} from '../types/Variable'
import { VariableArtifactState } from '../types/VariableArtifact'
import { isFormulaValid } from './mathlive'
import { textForNumberFormat } from './numberFormat'
import {
	parseSemicolonArrayString,
	removeTrailingSeparatorsFromArrayString,
	serializeToSemicolonArrayString,
	wrapWithBrackets
} from './string'

// eslint-disable-next-line react-refresh/only-export-components
const Delta = Quill.import('delta')

export const variablesMatch = (v1: VariableBaseModel, v2: VariableBaseModel) =>
	(!!v1.id && v1.id === v2.id) ||
	(!!v1.guid && v1.guid === v2.guid) ||
	(!!v1.name && !!v2.name && v1.name === v2.name)

//#region Formatting

const getExponentFromENotation = (eNotationString: string) => {
	const eParts = eNotationString.toLowerCase().split('e')
	const exp = Number(eParts[1])
	return exp
}

const parseRandomNumberFormat = (value: string, shouldUseENotation: boolean) => {
	const numberString = value.toString() // for backwards compatibility
	let shouldUsePlusSign = false
	let numParts = numberString.split('.')
	let decimalPlaces = numParts.length === 2 ? numParts[1].length : 0

	const num = Number(numberString)
	const eString = num.toExponential()
	const exp = getExponentFromENotation(eString)

	if (shouldUseENotation) {
		// if this number string is in e-notation
		if (numberString.toLowerCase().includes('e')) {
			// get the decimalPlaces from the original E-notation string
			const parts = numberString.toLowerCase().split('e')
			if (parts[0].includes('.')) {
				numParts = parts[0].split('.')
				decimalPlaces = numParts.length === 2 ? numParts[1].length : 0

				// account for user entered incorrect e-notation, where the exp changed
				const originalExp = getExponentFromENotation(numberString)
				decimalPlaces += exp - originalExp
			}
			// check original E-notation string for plus sign
			shouldUsePlusSign = numberString.includes('+')
		} // if this number string is not in e-notation, account for its conversion to e notation
		else {
			decimalPlaces += exp
		}
	}

	return { decimalPlaces, exp, shouldUsePlusSign }
}

export const formatRandomNumberValue = (min: string, max: string, step: string, value: Decimal) => {
	const shouldUseENotation =
		min.toString().toLowerCase().includes('e') ||
		max.toString().toLowerCase().includes('e') ||
		step.toString().toLowerCase().includes('e')

	const minResult = parseRandomNumberFormat(min, shouldUseENotation)
	const maxResult = parseRandomNumberFormat(max, shouldUseENotation)
	const stepResult = parseRandomNumberFormat(step, shouldUseENotation)

	let displayDecimals = Math.max(minResult.decimalPlaces, maxResult.decimalPlaces, stepResult.decimalPlaces)

	// if no number comes in as E-notation, display as generic number with max decimal places
	if (!shouldUseENotation) {
		return value.toFixed(displayDecimals)
	}

	// whether or not positive exponents will use a "+" (only if any incoming value has one)
	const shouldUsePlusSign = minResult.shouldUsePlusSign || maxResult.shouldUsePlusSign || stepResult.shouldUsePlusSign

	/**
	 * find the highest and lowest precision, use the diff as the number of decimal places
	 * examples:
	 *   1.543212E2 would give high 2, low -4
	 *   1.01E-1 would give high -1, low -3
	 *   final result = 2 to -4 = 6
	 */
	const highestPrecision = Math.max(minResult.exp, maxResult.exp, stepResult.exp)
	const lowestPrecision = Math.min(
		minResult.exp - minResult.decimalPlaces,
		maxResult.exp - maxResult.decimalPlaces,
		stepResult.exp - stepResult.decimalPlaces
	)
	displayDecimals = Math.abs(highestPrecision - lowestPrecision)

	/**
	 * make the final formatted number not have more precision as our smallest changing place
	 *
	 * example:
	 *   min = 10
	 *   max = 4E5
	 *   step = 1
	 *   value = 1,230
	 *
	 *   before: 1.23000E3 - shows 5 decimal places, but that is 1,230.0 and is more precise than our step of 1
	 *   after:  1.230E3
	 */
	const selectedExp = getExponentFromENotation(value.toExponential())
	displayDecimals = Math.max(displayDecimals + selectedExp - highestPrecision, 0)

	let result = value.toExponential(displayDecimals).replace('e', 'E')
	if (!shouldUsePlusSign) {
		result = result.replace('+', '')
	}
	return result
}

//#endregion Formatting

//#region Type Checks

const isNumberSetVariableBase = (variable: VariableBaseModel) => variable.variableType === VARIABLE_TYPE.NUMBER_SET

const isRandomNumberVariableBase = (variable: VariableBaseModel) =>
	variable.variableType === VARIABLE_TYPE.RANDOM_NUMBER

const isCalculatedValueVariableBase = (variable: VariableBaseModel) =>
	variable.variableType === VARIABLE_TYPE.CALCULATED_VALUE

const isRichTextVariableBase = (variable: VariableBaseModel) => variable.variableType === VARIABLE_TYPE.RICH_TEXT

export const isNumberSetVariableState = (variable: VariableState): variable is NumberSetVariableState =>
	isNumberSetVariableBase(variable)

export const isRandomNumberVariableState = (variable: VariableState): variable is RandomNumberVariableState =>
	isRandomNumberVariableBase(variable)

export const isCalculatedValueVariableState = (variable: VariableState): variable is CalculatedValueVariableState =>
	isCalculatedValueVariableBase(variable)

export const isRichTextVariableState = (variable: VariableState): variable is RichTextVariableState =>
	isRichTextVariableBase(variable)

export const isVariableComplexNumberFormatType = (variable: CalculatedValueVariableState) => {
	return (
		variable.parameters.numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_I ||
		variable.parameters.numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_RECTANGULAR_J ||
		variable.parameters.numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_DEGREE ||
		variable.parameters.numberFormatType === NUMBER_FORMAT_TYPE.COMPLEX_POLAR_RADIAN
	)
}

//#endregion Type Checks

//#region State Generation

export const generateNumberSetVariableStateWithParameters = (name = '', parameters = ['', '']) =>
	({
		guid: uuidv4(),
		name,
		parameters,
		variableType: VARIABLE_TYPE.NUMBER_SET,
		variableArtifacts: []
	} as NumberSetVariableState)

export const generateNumberSetVariableState = (name = '', valueCount = 2) =>
	generateNumberSetVariableStateWithParameters(name, Array(valueCount).fill(''))

export const generateRandomNumberVariableState = (name = '') =>
	({
		guid: uuidv4(),
		name,
		parameters: defaultRandomVariableParameters,
		variableArtifacts: [],
		variableType: VARIABLE_TYPE.RANDOM_NUMBER
	} as RandomNumberVariableState)

export const generateCalculatedValueVariableState = (name = '', formula = '') =>
	({
		guid: uuidv4(),
		name,
		parameters: {
			formula,
			numberFormatType: NUMBER_FORMAT_TYPE.NUMBER,
			rounding: 2
		},
		variableArtifacts: [],
		variableType: VARIABLE_TYPE.CALCULATED_VALUE
	} as CalculatedValueVariableState)

export const generateVariableArtifactState = (value = '') =>
	({
		guid: uuidv4(),
		quillContents: new Delta([{ insert: `${value}\n` }])
	} as VariableArtifactState)

export const generateRichTextVariableState = (name = '', valueCount = 2, firstValue = '') =>
	({
		guid: uuidv4(),
		name,
		parameters: undefined,
		variableType: VARIABLE_TYPE.RICH_TEXT,
		variableArtifacts: Array.from(Array(valueCount).keys()).map(i =>
			generateVariableArtifactState(i === 0 ? firstValue : undefined)
		)
	} as RichTextVariableState)

export const convertVariableToState = (
	variable: Variable,
	prevVariableState?: VariableState
): NumberSetVariableState | RandomNumberVariableState | CalculatedValueVariableState | RichTextVariableState => {
	switch (variable.variableType) {
		case VARIABLE_TYPE.NUMBER_SET: {
			return {
				id: variable.id,
				name: variable.name,
				variableType: variable.variableType,
				parameters: parseSemicolonArrayString(removeTrailingSeparatorsFromArrayString(variable.parameters)),
				variableArtifacts: []
			}
		}
		case VARIABLE_TYPE.RANDOM_NUMBER: {
			return {
				id: variable.id,
				name: variable.name,
				variableType: variable.variableType,
				parameters: (variable.parameters
					? JSON.parse(variable.parameters)
					: defaultRandomVariableParameters) as RandomNumberVariableParameters,
				variableArtifacts: []
			}
		}
		case VARIABLE_TYPE.CALCULATED_VALUE: {
			return {
				id: variable.id,
				name: variable.name,
				variableType: variable.variableType,
				parameters: (variable.parameters
					? JSON.parse(variable.parameters)
					: defaultCalculatedVariableParameters) as CalculatedValueVariableParameters,
				variableArtifacts: []
			}
		}
		case VARIABLE_TYPE.RICH_TEXT: {
			const prevRichTextVariableState = prevVariableState as RichTextVariableState | undefined
			return {
				id: variable.id,
				name: variable.name,
				variableType: variable.variableType,
				variableArtifacts: variable.variableArtifacts
					? [...variable.variableArtifacts]
							.sort((va1, va2) => va1.ordinal - va2.ordinal)
							.map(va => {
								const prevVariableArtifactState = prevRichTextVariableState?.variableArtifacts.find(
									pva => pva.id === va.id
								)
								const variableArtifactState: VariableArtifactState = {
									id: va.id,
									quillContents: prevVariableArtifactState
										? prevVariableArtifactState.quillContents
										: !va.artifact.url
										? new Delta([{ insert: '\n' }])
										: undefined
								}
								return variableArtifactState
							})
					: []
			}
		}
	}
}

/**
 * Initialize a new variable of given type, attempting to populate parameters or name
 * utilizing highlighted text (if any)
 * @param getHighlightedText A function that extracts any highlighted text
 * @param variableType The type to initialize to
 * @param undefinedVariableName The value to use for name if we are creating from an undefined variable
 * @returns An initialized variable of the specified type
 */
export const variableWithParametersOrNameFromHighlightedText = (
	getHighlightedText: (() => string | undefined) | undefined,
	variableType: VARIABLE_TYPE,
	undefinedVariableName?: string
) => {
	const highlightedText = getHighlightedText ? getHighlightedText() : undefined
	const name =
		highlightedText && variableNameRegEx.test(highlightedText)
			? highlightedText
			: undefinedVariableName
			? undefinedVariableName
			: ''

	switch (variableType) {
		case VARIABLE_TYPE.NUMBER_SET: {
			let shouldSetName = true
			let parameters: string[] | undefined = []
			if (highlightedText && validateNumericSemicolonArrayString(wrapWithBrackets(highlightedText))) {
				parameters = parseSemicolonArrayString(
					removeTrailingSeparatorsFromArrayString(wrapWithBrackets(highlightedText))
				)
				shouldSetName = false
			}
			return generateNumberSetVariableStateWithParameters(shouldSetName ? name : undefined, parameters)
		}
		case VARIABLE_TYPE.RANDOM_NUMBER:
			return {
				guid: uuidv4(),
				name,
				parameters: defaultRandomVariableParameters,
				variableArtifacts: [],
				variableType
			} as RandomNumberVariableState
		case VARIABLE_TYPE.CALCULATED_VALUE:
			return {
				guid: uuidv4(),
				name,
				parameters: {
					formula: '',
					numberFormatType: NUMBER_FORMAT_TYPE.NUMBER,
					rounding: 2
				},
				variableArtifacts: [],
				variableType
			} as CalculatedValueVariableState
		case VARIABLE_TYPE.RICH_TEXT:
			return generateRichTextVariableState(name, 2, !name && highlightedText ? highlightedText : undefined)
	}
}

//#endregion State Generation

//#region Request Models

export const serializeVariableStateParameters = (variableState: VariableState) => {
	if (isNumberSetVariableState(variableState)) {
		return serializeToSemicolonArrayString(variableState.parameters)
	}
	if (isRandomNumberVariableState(variableState)) {
		const trimmedBlacklistParameters = update(variableState.parameters, {
			blacklist: {
				$set: removeTrailingSeparatorsFromArrayString(variableState.parameters.blacklist)
			}
		})
		return JSON.stringify(trimmedBlacklistParameters)
	}
	if (isCalculatedValueVariableState(variableState)) {
		return JSON.stringify(variableState.parameters)
	}
	if (isRichTextVariableState(variableState)) {
		return null
	}
	return null
}

//#endregion

//#region Parameter Display

export const getParameterTextFromVariable = (variableState: VariableState) => {
	if (isNumberSetVariableState(variableState)) {
		if (!variableState.parameters || variableState.parameters.length === 0) return ''
		return variableState.parameters.join(', ')
	}
	if (isRandomNumberVariableState(variableState)) {
		const { min, max, step, blacklist, numberFormatType } = variableState.parameters
		const rangeExamples: Array<Decimal | string> = []
		const blacklistValues = uniq(parseSemicolonArrayString(removeTrailingSeparatorsFromArrayString(blacklist)))
		// Use commas and spaces for display purposes only
		const blacklistText = blacklistValues.join(', ')

		if (min !== undefined && max !== undefined && step !== undefined) {
			let exampleValue = new Decimal(min)
			let stepMultiplier = 0
			const blacklistValueDecimalStrings = blacklistValues.map(v => new Decimal(v).toString())
			// Get up to three example values to show the step, skipping items in the blacklist
			while (exampleValue.lessThanOrEqualTo(max) && rangeExamples.length < 3) {
				if (!blacklistValueDecimalStrings.includes(exampleValue.toString())) {
					rangeExamples.push(formatRandomNumberValue(min, max, step, exampleValue))
				}
				stepMultiplier++
				exampleValue = new Decimal(min).plus(new Decimal(step).times(stepMultiplier))
			}

			// Found 3 examples starting at the minimum value, now find maximum possible value.
			// If there are less than 3 examples, we already got to the maximum value in the last loop
			if (rangeExamples.length === 3) {
				let highestMultiplier = new Decimal(max).minus(min).dividedBy(step).floor()
				let highestValue = new Decimal(min).plus(new Decimal(step).times(highestMultiplier))

				while (
					blacklistValueDecimalStrings.includes(highestValue.toString()) &&
					highestMultiplier.greaterThan(0)
				) {
					highestMultiplier = highestMultiplier.minus(1)
					highestValue = new Decimal(min).plus(new Decimal(step).times(highestMultiplier))
				}
				const formattedHighestValue = formatRandomNumberValue(min, max, step, highestValue)

				// If the highest number is already in the example list don't add it again
				if (!rangeExamples.includes(formattedHighestValue)) {
					rangeExamples.push(formattedHighestValue)

					// Insert ellipsis if there was a jump after the three lowest values
					if (highestMultiplier.greaterThan(stepMultiplier)) {
						rangeExamples.splice(3, 0, '…')
					}
				}
			}
		}
		return (
			<>
				<span>{rangeExamples.join(', ')}</span>
				{!!blacklistText && (
					<>
						<br className="lh-copy" />
						<span className="b">Exclude:</span>
						<span>&nbsp;{blacklistText}</span>
					</>
				)}
				<br className="lh-copy" />
				{!!numberFormatType && (
					<>
						<span className="b">Format:</span>
						<span>&nbsp;{textForNumberFormat(numberFormatType)}</span>
					</>
				)}
			</>
		)
	}
	if (isCalculatedValueVariableState(variableState)) {
		const { formula, numberFormatType, rounding } = variableState.parameters
		return (
			<>
				<InlineMath
					enableOverflowScrolling
					latex={formula ?? ''}
					className="f5 variable-calculated-cell db"
					style={{ maxWidth: '15vw' }}
				/>
				<br className="lh-copy" />
				{!!numberFormatType && (
					<p className="ma0">
						<span className="b">Format:</span>
						<span>&nbsp;{textForNumberFormat(numberFormatType)}</span>
					</p>
				)}
				{!!rounding && numberFormatType !== NUMBER_FORMAT_TYPE.FRACTION && (
					<p className="ma0">
						<span className="b">Rounding:</span>
						<span>&nbsp;{rounding}</span>
					</p>
				)}
			</>
		)
	}
	if (isRichTextVariableState(variableState)) {
		const variableArtifactCount = variableState.variableArtifacts.length
		return (
			<p className="ma0">
				{variableArtifactCount} Text/Image value{variableArtifactCount !== 1 ? 's' : ''}
			</p>
		)
	}
	return ''
}

//#endregion Parameter Display

//#region Validation

export const hasValidName = (variable: VariableState) => variableNameRegEx.test(variable.name)

export const hasConflictingName = (variable: VariableState, existingVariables?: VariableState[]) =>
	!!existingVariables?.find(
		v => v.name === variable.name && ((!!v.id && v.id !== variable.id) || (!!v.guid && v.guid !== variable.guid))
	)

export const validateNumericSemicolonArrayString = (
	semicolonArrayString: string,
	variableType: string = VARIABLE_TYPE.NUMBER_SET
) => {
	if (semicolonArrayString.length === 0) return false
	// remove all trailing separators/values before validation
	const numericStringArray = parseSemicolonArrayString(removeTrailingSeparatorsFromArrayString(semicolonArrayString))
	return validateNumericStringArray(numericStringArray, variableType)
}

export const validateNumericStringArray = (
	numericStringArray: string[],
	variableType: string = VARIABLE_TYPE.NUMBER_SET
) => {
	/*
		Number Format Pattern covers: number, sciNotation, ENotation or percentages
		- start of string
		- optional '-'
		- optional
			- zero or more digits
			- period
		- one or more digits
		- optional
			- either
				- Capital E
				- optional '+' or '-'
				- one or more digits
			- or
				- either 'x' or 'X'
				- literal '10^'
				- optional '-'
				- one or more digits
			- or %
		- end of string
	*/
	const numberPattern = /^(-?(?:(?:\d*\.)?\d+(?:(?:E[+-]?\d+)|(?:[Xx]10\^-?\d+)|(%?))?))$/

	/*
		Same as NumberPattern but without formatting
	*/
	const numberBlacklistPattern = /^(-?(?:(?:\d*\.)?\d+))$/

	/*
		Number With Separators covers: numbers with separators and US dollars
		- start of string
		- no leading zeroes, with optional decimal place and zeroes
		- optional '-'
		- optional '$'
		- one to three digits
		- optional
			- zero or more
				- comma followed by three digits
		- optional
			- one period
			- one or more digits
		-end of string
	 */
	const numberWithSeparatorsPattern = /^(?!0+(?:\.0+)?)-?\$?\d{1,3}(,\d{3})*(\.\d+)?$/

	/*
		Fraction format covers: proper and improper fractions
		- start of string
		- optional -
		- one or more digits
		- /
		- optional -
		- one or more digits
		- end of string
	 */
	const fractionPattern = /^-?(\d+\/-?\d+)$/
	switch (variableType) {
		case VARIABLE_TYPE.NUMBER_SET: {
			return numericStringArray.every(
				p => numberPattern.test(p) || numberWithSeparatorsPattern.test(p) || fractionPattern.test(p)
			)
		}
		case VARIABLE_TYPE.RANDOM_NUMBER: {
			// blacklists do not support fractions, e/sci notation
			return numericStringArray.every(p => numberBlacklistPattern.test(p))
		}
		default:
			return false
	}
}

export const hasDefinedParameters = (variableState: VariableState) => {
	if (isNumberSetVariableState(variableState))
		return !!variableState.parameters && variableState.parameters.length > 0
	if (isRandomNumberVariableState(variableState))
		return (
			variableState.parameters.min !== undefined &&
			variableState.parameters.max !== undefined &&
			variableState.parameters.step !== undefined
		)
	if (isCalculatedValueVariableState(variableState)) return !!variableState.parameters.formula
	if (isRichTextVariableState(variableState)) return true
	return false
}

export const hasDefinedParametersForAllVariables = (variables: VariableState[]) => {
	return variables.every(v => hasDefinedParameters(v))
}

/**
 * Validate a given variable for the problem level.
 *
 * NOTE: this method is intended for use OUTSIDE the variable edit modal, and does not validate all parameters,
 * since the variable edit modal cannot be closed with invalid parameters.
 * @param variable The variable to validate
 * @param allVariables All variables from the containing problem
 * @returns The validity level of the variable
 */
export const isVariableValid = (variable: VariableState, allVariables: VariableState[]): VALIDITY_LEVEL => {
	// validate that the calculated variable formula does not contain undefined variables,
	// does not contain rich text variables,
	// and does not contain invalid symbols or commands
	if (isCalculatedValueVariableState(variable)) {
		const { formula } = variable.parameters
		const supportedCommands = NUMERIC_SOLUTION_COMMANDS
		return isFormulaValid(formula, allVariables, supportedCommands)
	}

	return VALIDITY_LEVEL.VALID
}

//#endregion Validation

//#region Changes

export const getRenamedVariables = (variables: VariableState[], prevVariables: VariableState[]) => {
	return variables.reduce((retval: DiffObject<VariableState>[], v) => {
		const match = prevVariables.find(
			pv => v.name !== pv.name && ((!!v.id && v.id === pv.id) || (!!v.guid && v.guid === pv.guid))
		)
		if (match) {
			retval.push({
				to: v,
				from: match
			})
		}
		return retval
	}, [])
}

/** Returns an array of Variables that have just had undefined parameters set. */
export const getChangedTypeOrParametersVariables = (variables: VariableState[], prevVariables: VariableState[]) => {
	return variables.reduce((retval: DiffObject<VariableState>[], v) => {
		const match = prevVariables.find(
			pv =>
				((!!v.id && v.id === pv.id) || (!!v.guid && v.guid === pv.guid)) &&
				(v.variableType !== pv.variableType || !isEqual(v.parameters, pv.parameters))
		)
		if (match) {
			retval.push({
				to: v,
				from: match
			})
		}
		return retval
	}, [])
}

export const didVariablesChange = (
	variables: VariableState[] | undefined,
	prevVariables: VariableState[] | undefined
) => {
	const renamedVariables = variables && prevVariables ? getRenamedVariables(variables, prevVariables) : []
	const changedTypeOrParametersVariables =
		variables && prevVariables ? getChangedTypeOrParametersVariables(variables, prevVariables) : []
	const variablesDidChange =
		(!!variables && !prevVariables) ||
		(!variables && !!prevVariables) ||
		(!!variables &&
			!!prevVariables &&
			(variables.length !== prevVariables.length ||
				!variables.every(v =>
					prevVariables.some(pv => (!!v.id && v.id === pv.id) || (!!v.guid && v.guid === pv.guid))
				) ||
				renamedVariables.length > 0 ||
				changedTypeOrParametersVariables.length > 0))
	return { variablesDidChange, renamedVariables }
}

//#endregion Changes

//#region LaTeX

export const extractVariable = (name: string, existingVariables?: VariableState[]) => {
	const newVariable: NumberSetVariableState = {
		guid: uuidv4(),
		name,
		variableType: VARIABLE_TYPE.NUMBER_SET,
		parameters: [],
		variableArtifacts: []
	}
	const result = {
		variable: newVariable as VariableState,
		isNew: true
	}
	if (existingVariables) {
		const existingVariable = existingVariables.find(variable => variable.name === name)
		if (existingVariable) {
			result.variable = existingVariable
			result.isNew = false
		}
	}
	return result
}

export const extractVariableFromBrackets = (value: string, existingVariables?: VariableState[]) => {
	const variableName = value.replace(/({{)|(}})/gm, '')
	return extractVariable(variableName, existingVariables)
}

export const renameVariablesInLatex = (latex: string, renamedVariables: DiffObject<VariableState>[]) => {
	if (renamedVariables.length < 1) {
		return latex
	}
	let newLatex = latex
	let match = latexVariableRegEx.exec(newLatex)
	while (match) {
		const variableName = match[1]
		const renamedVariable = renamedVariables.filter(v => v.from.name === variableName)[0]
		if (renamedVariable) {
			newLatex = newLatex.replace(`\\variable{${variableName}}`, `\\variable{${renamedVariable.to.name}}`)
		}
		match = latexVariableRegEx.exec(newLatex)
	}
	return newLatex
}

/**
 * Find \variable commands and replace them with values
 * @param latex A latex string
 * @param variableValuesDictionary A dictionary or variable names and values
 * @param preservePills Choose whether to keep the replaced value in a variable pill or not. Default false.
 * @returns An updated latex string
 */
export const replaceVariableValuesInLatex = (
	latex: string,
	variableValuesDictionary: Dictionary<string>,
	preservePills = false
) => {
	// check the latex for which multiplication command to insert, either `\cdot ` if found, otherwise `\times `
	const multCommand = latex.includes('\\cdot ') ? '\\cdot ' : '\\times '

	// insert multCommand between adjacent variables
	let newLatex = latex.replace(adjacentLatexVariableRegEx, match => `${match}${multCommand}`)
	newLatex = newLatex.replace(latexVariableBeforeDigitRegEx, match => `${match}${multCommand}`)
	newLatex = newLatex.replace(latexVariableAfterDigitRegEx, `$1$2${multCommand}$3`)

	// construct a string array of latex parts
	const latexParts: string[] = []

	// find the first \variable, if any
	let match = latexVariableRegEx.exec(newLatex)

	// save the substring from the start of the latex up to the first match, if any
	// otherwise it will be the whole string
	if (!match || match.index > 0) {
		latexParts.push(newLatex.substring(0, match ? match.index : undefined))
	}

	// start looping \variable matches
	while (match) {
		const matchString = match[0]
		const matchVariableName = match[1]
		const matchEndIndex = match.index + matchString.length

		// find the next \variable, if any
		const nextMatch = latexVariableRegEx.exec(newLatex)
		// find the string between the current match and the next, or to the end of the latex
		const nextString = newLatex.substring(
			matchEndIndex,
			nextMatch && nextMatch.index > matchEndIndex ? nextMatch.index : undefined
		)

		const lastPart = latexParts[latexParts.length - 1]
		const isVariableWrapped =
			lastPart &&
			nextString &&
			Object.entries(VISIBLE_DELIMITER_PAIRS).some(
				([left, right]) => lastPart.endsWith(left) && nextString.startsWith(right)
			)
		const isFirstDualCommandGroup =
			lastPart &&
			DUAL_GROUP_COMMANDS.some(command => lastPart.endsWith(`${command}{`)) &&
			nextString &&
			nextString.startsWith('}')
		const isSecondDualCommandGroup =
			lastPart &&
			DUAL_GROUP_COMMANDS.some(command => {
				const commandRegex = new RegExp(`.*\\${command}{.*}{$`, 'gm')
				return commandRegex.test(lastPart)
			}) &&
			nextString &&
			nextString.startsWith('}')

		// replace the \variable with its formatted value
		let variableValue = variableValuesDictionary[matchVariableName]
		if (
			variableValue &&
			// do not replace if the formatted value is an error type
			!Object.values(SOLUTION_ENGINE_ERROR_TYPE)
				.map(v => v as string)
				.includes(variableValue)
		) {
			if (preservePills) {
				variableValue = `\\variable{${variableValue}}`
			}
			// wrap the value in parentheses if it is a fraction, sci notation, e notation or negative and
			// * not already wrapped
			// * not a numerator
			// * not a denominator
			else if (!isVariableWrapped && !isFirstDualCommandGroup && !isSecondDualCommandGroup) {
				if (latexFractionRegEx.exec(variableValue)) {
					variableValue = `\\left(${variableValue}\\right)`
					latexFractionRegEx.lastIndex = 0
				}
				if (latexSciNotationRegEx.exec(variableValue)) {
					variableValue = `\\left(${variableValue}\\right)`
					latexSciNotationRegEx.lastIndex = 0
				}
				// only wrap e notation or negative values if before an exponent
				if (
					(latexENotationRegEx.exec(variableValue) || variableValue.indexOf('-') === 0) &&
					nextString &&
					nextString.startsWith('^')
				) {
					variableValue = `\\left(${variableValue}\\right)`
					latexENotationRegEx.lastIndex = 0
				}
			}
			latexParts.push(variableValue)
		} else {
			latexParts.push(matchString)
		}
		latexParts.push(nextString)
		match = nextMatch
	}

	// construct the final latex string
	newLatex = latexParts.join('')

	// simplify adjacent + and - signs
	newLatex = newLatex.replace(/--/g, '+')
	newLatex = newLatex.replace(/\+-/g, '-')
	newLatex = newLatex.replace(/-\+/g, '-')
	newLatex = newLatex.replace(/\+\+/g, '+')

	return newLatex
}

export const wrapUnwrappedSingleSubscript = (variableName: string) => {
	let newVariableName = variableName
	let match = variableNameUnwrappedSingleSubRegEx.exec(newVariableName)
	while (match) {
		const subscript = match[2]
		newVariableName = newVariableName.replace(subscript, `_{${subscript.replace('_', '')}}`)
		match = variableNameUnwrappedSingleSubRegEx.exec(newVariableName)
	}
	return newVariableName
}

export const unwrapWrappedSingleSubscript = (variableName: string) => {
	let newVariableName = variableName
	let match = variableNameWrappedSingleSubRegEx.exec(newVariableName)
	while (match) {
		const subscript = match[2]
		const subscriptInner = match[3]
		newVariableName = newVariableName.replace(subscript, `_${subscriptInner}`)
		match = variableNameWrappedSingleSubRegEx.exec(newVariableName)
	}
	return newVariableName
}

//#endregion LaTeX
